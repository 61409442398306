import React from 'react';
import { Card, Text } from '@tremor/react';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MiniCalendar = ({ currentDate, onDateSelect }) => {
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const days = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    days.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return (
    <Card className="w-full max-w-[250px]">
      <div className="grid grid-cols-7 gap-1 text-xs">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-medium p-1">
            {day.charAt(0)}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`text-center p-1 cursor-pointer hover:bg-gray-100 ${
              day === currentDate.getDate() ? 'bg-blue-100' : ''
            }`}
            onClick={() => day && onDateSelect(day)}
          >
            <Text>{day}</Text>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default MiniCalendar; 