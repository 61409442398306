import React from 'react';
import { Button, Title } from '@tremor/react';
import { ChevronLeft, ChevronRight } from "lucide-react";

const CalendarHeader = ({ currentDate, onPrevMonth, onNextMonth }) => {
  return (
    <div className="flex items-center space-x-4">
      <Button size="xs" variant="secondary">Today</Button>
      <div className="flex items-center space-x-2">
        <Button size="xs" variant="secondary" icon={ChevronLeft} onClick={onPrevMonth} />
        <Button size="xs" variant="secondary" icon={ChevronRight} onClick={onNextMonth} />
      </div>
      <Title>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</Title>
    </div>
  );
};

export default CalendarHeader; 