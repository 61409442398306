import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";

export const TouchpointContext = createContext();

export const TouchpointProvider = ({ children }) => {
  const { salesAgentCD, BASE_URL, oktaId, token, custcd } =
    useContext(AppContext);

  const [touchpoints, setTouchpoints] = useState([]);

  const fetchTouchpoints = async () => {
    if (!salesAgentCD) return;
    try {
      const newPayload = {
        salesagentcd: salesAgentCD,
        custcd: custcd,
        oktaid: oktaId,
      };

      // Make the GET request with the query parameters
      const response = await axios.get(`${BASE_URL}/get-touchpoints`, {
        params: newPayload, // Pass the parameters here
        headers: { Authorization: `Bearer ${token}` },
      });

    // Filter touchpoints where the status is not 'deleted'
    const filteredTouchpoints = response.data.filter(touchpoint => touchpoint.status !== "deleted");

    // Update state with filtered touchpoints
    setTouchpoints(filteredTouchpoints);

      // console.log("Filtered Touchpoints", filteredTouchpoints);
    } catch (err) {
      setTouchpoints([]);
      console.log("Problem with fetchTouchpoints:", err);
      if (err.response) {
        console.log("Error response:", err.response);
      }
    }
  };

  // Add Touchpoint
  const addTouchpoint = async (touchpointData) => {
    // Ensure all required fields are provided in the touchpointData
    const touchpointPayload = {
      touchpointsid: touchpointData.touchpointsid, // UUID4
      custcd: touchpointData.custcd, // Customer code
      custname: touchpointData.custname,
      salesagentcd: salesAgentCD, // Sales agent code
      tp_type: touchpointData.tp_type, // Type of touchpoint (e.g., "call", "email", "meeting", etc.)
      summary: touchpointData.summary, // Summary of the touchpoint
      touchpoint_date: touchpointData.touchpoint_date, // Touchpoint date (ISO 8601 format)
      created_date: touchpointData.created_date, // Date the touchpoint was created
      system_generated: touchpointData.system_generated, // Whether the touchpoint is system-generated (true/false)
      visible: touchpointData.visible, // Whether the touchpoint is visible on the timeline
      oktaid: oktaId, // Okta ID of the creator
      bookmarked: touchpointData.bookmarked, // Whether the touchpoint is bookmarked
      status: touchpointData.status, // Status of the touchpoint
      impact: touchpointData.impact, // Impact on revenue
      response_status: touchpointData.response_status, // Whether the touchpoint has been responded to
      metadata: touchpointData.metadata, // Metadata (with tp_type matching)
    };

    try {
      console.log("Sending touchpoint data:", touchpointPayload); // Add this line
      const response = await axios.post(
        `${BASE_URL}/create-touchpoint`,
        touchpointPayload, // Use the structured payload
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Touchpoint created successfully");
      fetchTouchpoints(); // Refresh touchpoints after creation
      return response.data;
    } catch (err) {
      console.log("Problem with addTouchpoint:", err);
      throw err;
    }
  };

  const updateTouchpoint = async (touchpointData) => {
    try {
      // Prepare payload according to backend requirements
      const touchpointPayload = {
        touchpointsid: touchpointData.touchpointsid, // UUID of the touchpoint
        tp_type: touchpointData.tp_type, // Type of touchpoint
        summary: touchpointData.summary, // Summary of the touchpoint
        touchpoint_date: touchpointData.touchpoint_date, // Date of the touchpoint (ISO format)
        visible: touchpointData.visible, // Visibility on the timeline
        bookmarked: touchpointData.bookmarked, // Whether it is bookmarked
        status: touchpointData.status, // Status of the touchpoint
        impact: touchpointData.impact, // Revenue impact (if applicable)
        response_status: touchpointData.response_status, // Response status (boolean)
        updated_date: touchpointData.updated_date, // Last updated timestamp (ISO format)
        metadata: touchpointData.metadata, // Metadata object
        oktaid: oktaId, // Okta ID of the updater
        salesagentcd: salesAgentCD, // Sales agent code
        custcd: touchpointData.custcd, // Customer code
      };

      // Ensure the `tp_type` matches `metadata.tp_type`
      if (touchpointPayload.tp_type !== touchpointPayload.metadata?.tp_type) {
        throw new Error("The type of the touchpoint must match the metadata.");
      }

      // Send update request to the backend
      const response = await axios.post(
        `${BASE_URL}/update-touchpoint`,
        touchpointPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Touchpoint updated successfully");
      fetchTouchpoints(); // Refresh the touchpoints list
      return response.data;
    } catch (err) {
      console.error("Problem with updateTouchpoint:", err);
      if (err.response) {
        console.error("Error response from server:", err.response);
      }
      throw err; // Re-throw the error for further handling
    }
  };

  // Delete Touchpoint
  const deleteTouchpoint = async (touchpointData) => {
    // Prepare the payload for the delete request
    const touchpointPayload = {
      touchpointsid: touchpointData.touchpointsid, // UUID of the touchpoint to be deleted
      updated_date: touchpointData.updated_date, // The updated timestamp (ISO format)
      custcd: touchpointData.custcd, // Customer code
      salesagentcd: salesAgentCD, // Sales agent code
      oktaid: oktaId, // Okta ID of the user performing the deletion
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/delete-touchpoint`,
        touchpointPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Touchpoint deleted successfully");
      fetchTouchpoints(); // Refresh touchpoints after deletion
      return response.data;
    } catch (err) {
      console.log("Problem with deleteTouchpoint:", err);
      throw err;
    }
  };

  useEffect(() => {
    fetchTouchpoints();
  }, [custcd, salesAgentCD]);

  return (
    <TouchpointContext.Provider
      value={{
        touchpoints,
        addTouchpoint,
        updateTouchpoint,
        deleteTouchpoint,
      }}
    >
      {children}
    </TouchpointContext.Provider>
  );
};
