import React, { useState } from 'react';
import { ScrollArea } from 'components/ui/scroll-area';
import ResponsiveModal from './components/responsive-modal';
import { InsightCard } from './insight-card';
import { CardPopUp } from './card-popup';
import { Text } from 'components/catalyst/text';

export function InsightsList({ items, scrollHeight = 670 }) {
  const [openNoteId, setOpenNoteId] = useState(null);

  if (!items || items.length === 0) {
    return;
  }

  // Sort items to place newest insights at the top
  const sortedItems = items.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));

  return (
    <ScrollArea className={`h-[${scrollHeight}px]`}>
      <div className="flex flex-col gap-2 pt-0">
        {sortedItems.map((item) => (
          <ResponsiveModal
            key={item.notesid}
            trigger={
              <button 
                onClick={() => setOpenNoteId(item.notesid)}
                className="w-full transition-all duration-200 rounded-lg"
              >
                <div className="transition-opacity hover:opacity-90">
                  <InsightCard item={item} />
                </div>
              </button>
            }
            title={item.cosailor_task[0] ? item.cosailor_task[0].toString() : ''}
            description={
              <CardPopUp item={item} />
            }
          />
        ))}
      </div>
    </ScrollArea>
  );
}