import React, { useContext, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { CreateVoice } from "components/action-cards-component/create-voice";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { Button } from "components/ui/button";
import { Switch } from "components/ui/switch";
import CustomerSearch from "./CustomerSearch";
import { SearchAccountSwitch } from "components/touchpoint/components/SearchAccountSwitch";
import { TouchpointContext } from "contexts/TouchpointContext";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";

const AddTouchpoint = ({ custcd, custname }) => {
  const { addTouchpoint } = useContext(TouchpointContext);
  const [selectedCustomer, setSelectedCustomer] = useState(
    custcd && custname ? { custcd, custname } : null
  );
  const [touchpointType, setTouchpointType] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [status, setStatus] = useState("completed");
  const [content, setContent] = useState("");
  const [revenueImpact, setRevenueImpact] = useState("");
  const [customerImpact, setCustomerImpact] = useState("");
  const [impactTimeline, setImpactTimeline] = useState("");
  const [includeTimeline, setIncludeTimeline] = useState(false);

  const [transcript, setTranscript] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedCustomer || !touchpointType || !content) {
      alert("Please select a customer, touchpoint type, and add content.");
      return;
    }

    const touchpointDate = new Date(date);
    const formattedDate =
      touchpointDate.toISOString().slice(0, 19) +
      "." +
      touchpointDate.getMilliseconds().toString().padStart(3, "0") +
      "000"; // Add microseconds

    const createdDate = new Date();
    const formattedCreatedDate =
      createdDate.toISOString().slice(0, 19) +
      "." +
      createdDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    const metadata =
      touchpointType === "email"
        ? {
            tp_type: "email",
            subject: null,
            ccs: null,
            bccs: null,
            num_attachments: null,
            senders: null,
            recipients: null,
            content: content,
            disposition: null,
            notes: null,
            followup: null,
          }
        : touchpointType === "call"
        ? {
            tp_type: "call",
            participants: null,
            duration: null,
            num_participants: null,
            has_recording: null,
            disposition: null,
            content: content,
            notes: null,
            followup: null,
          }
        : touchpointType === "text"
        ? {
            tp_type: "text",
            senders: null,
            recipients: null,
            content: content,
            disposition: null,
            notes: null,
            followup: null,
          }
        : touchpointType === "in-person"
        ? {
            tp_type: "in-person",
            duration: null,
            num_participants: null,
            participants: null,
            location: null,
            disposition: null,
            content: content,
            notes: null,
            followup: null,
          }
        : {};

    const getSummary = (type) => {
      switch (type) {
        case "email":
          return "Email Sent";
        case "call":
          return "Call Made";
        case "text":
          return "SMS Sent";
        case "in-person":
          return "Meeting Scheduled";
        default:
          return "";
      }
    };

    const touchpointData = {
      touchpointsid: uuidv4(),
      custcd: selectedCustomer.custcd,
      custname: selectedCustomer.custname,
      tp_type: touchpointType,
      summary: getSummary(touchpointType),
      touchpoint_date: formattedDate,
      system_generated: false,
      visible: true,
      bookmarked: false,
      status: status,
      impact: parseFloat(revenueImpact) || 0,
      response_status: false,
      created_date: formattedCreatedDate,
      metadata: metadata,
    };

    console.log("Touchpoint data being sent:", touchpointData);
    console.log("Form submitted with data:", {
      selectedCustomer,
      touchpointType,
      content,
    });

    try {
      await addTouchpoint(touchpointData);
      toast.success("Touchpoint added successfully!");
      setSelectedCustomer(null);
      setTouchpointType("");
      setDate(new Date().toISOString().split("T")[0]);
      setContent("");
      setRevenueImpact("");
      setCustomerImpact("");
      setImpactTimeline("");
      setIncludeTimeline(false);
    } catch (err) {
      console.error("Error adding touchpoint:", err);
      toast.error("Failed to add touchpoint. Please try again.");
    }
  };

  // const handleSelectCustomer = (customerId, customerName) => {
  //   setSelectedCustomer({ id: customerId, name: customerName });
  // };

  const renderTypeSpecificFields = () => {
    switch (touchpointType) {
      case "email":
      // return (
      //   <>
      //     <div className="space-y-2">
      //       <Label htmlFor="sender">From Email</Label>
      //       <Input id="sender" type="email" placeholder="Sender email" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="recipients">To</Label>
      //       <Input
      //         id="recipients"
      //         placeholder="Primary recipients (comma-separated)"
      //       />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="cc">CC</Label>
      //       <Input id="cc" placeholder="CC recipients (comma-separated)" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="bcc">BCC</Label>
      //       <Input id="bcc" placeholder="BCC recipients (comma-separated)" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="subject">Subject</Label>
      //       <Input id="subject" placeholder="Email subject" />
      //     </div>
      //   </>
      // );
      case "phone":
      // return (
      //   <>
      //     <div className="space-y-2">
      //       <Label htmlFor="caller">Caller</Label>
      //       <Input id="caller" placeholder="Phone number" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="recipient">Recipient</Label>
      //       <Input id="recipient" placeholder="Recipient phone number" />
      //     </div>
      //   </>
      // );
      case "sms":
      // return (
      //   <>
      //     <div className="space-y-2">
      //       <Label htmlFor="sender">From</Label>
      //       <Input id="sender" placeholder="Sender phone number" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="recipients">To</Label>
      //       <Input
      //         id="recipients"
      //         placeholder="Recipient phone numbers (comma-separated)"
      //       />
      //     </div>
      //   </>
      // );
      case "in-person":
      // return (
      //   <>
      //     <div className="space-y-2">
      //       <Label htmlFor="location">Location</Label>
      //       <Input id="location" placeholder="Meeting location" />
      //     </div>
      //     <div className="space-y-2">
      //       <Label htmlFor="attendees">Attendees</Label>
      //       <Input
      //         id="attendees"
      //         placeholder="Attendee names (comma-separated)"
      //       />
      //     </div>
      //   </>
      // );
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add New Touchpoint</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="customer">Customer *</Label>
            {/* <CustomerSearch onSelectCustomer={handleSelectCustomer} /> */}
            <SearchAccountSwitch
              selectedAccount={selectedCustomer}
              setSelectedAccount={setSelectedCustomer}
            />
            {selectedCustomer && (
              <p className="text-sm text-muted-foreground mt-1">
                Selected Customer: {selectedCustomer.custname}
              </p>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
            <div className="space-y-2 w-full">
              <Label htmlFor="type">Type *</Label>
              <Select onValueChange={setTouchpointType} value={touchpointType}>
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="email">Email</SelectItem>
                  <SelectItem value="call">Phone</SelectItem>
                  <SelectItem value="text">SMS</SelectItem>
                  <SelectItem value="in-person">In-Person</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2 w-full">
              <Label htmlFor="timestamp">Date *</Label>
              <Input
                id="timestamp"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="space-y-2 w-full">
              <Label htmlFor="status">Status *</Label>
              <Select
                value={status}
                onValueChange={setStatus}
                defaultValue="completed"
              >
                <SelectTrigger>
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="completed">Completed</SelectItem>
                  <SelectItem value="scheduled">Scheduled</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <>
            {renderTypeSpecificFields()}
            <div className="space-y-2">
              <Label htmlFor="content">Content *</Label>
              <Tabs defaultValue="text" className="w-full">
                <TabsList>
                  <TabsTrigger value="text">Text Input</TabsTrigger>
                  <TabsTrigger value="voice">Voice Input</TabsTrigger>
                </TabsList>
                <TabsContent value="text">
                  <Textarea
                    id="content"
                    placeholder={
                      touchpointType === "email"
                        ? "Email body"
                        : "Touchpoint details"
                    }
                    // placeholder="Enter touchpoint details here"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="mt-2"
                  />
                </TabsContent>
                <TabsContent value="voice">
                  <CreateVoice
                    title={title}
                    setTitle={setTitle}
                    content={content}
                    setContent={setContent}
                    selectedAccount={selectedCustomer}
                    setSelectedAccount={setSelectedCustomer}
                    setType={setTouchpointType}
                    setTranscript={setTranscript}
                    setIsRecording={setIsRecording}
                    setErrorMessage={setErrorMessage}
                  />
                </TabsContent>
              </Tabs>
            </div>
            {/* <div className="space-y-4 border rounded-lg p-4 bg-muted/10">
                <h3 className="font-medium">Projected Impact</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="revenue-impact">Revenue Impact ($)</Label>
                    <Input
                      id="revenue-impact"
                      type="number"
                      placeholder="Projected revenue increase"
                      min="0"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="customer-impact">Customer Growth</Label>
                    <Input
                      id="customer-impact"
                      type="number"
                      placeholder="Projected customer increase"
                      min="0"
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="impact-timeline">
                    Impact Timeline (days)
                  </Label>
                  <Input
                    id="impact-timeline"
                    type="number"
                    placeholder="Days until impact is realized"
                    min="1"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="impact-notes">Impact Notes</Label>
                  <Textarea
                    id="impact-notes"
                    placeholder="Additional details about projected impact..."
                  />
                </div>
              </div> */}
            {/* <div className="flex items-center space-x-2">
                <Switch id="include-timeline" />
                <Label htmlFor="include-timeline">Include in Timeline</Label>
              </div> */}
            <Button type="submit" className="w-full">
              Add Touchpoint
            </Button>
          </>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTouchpoint;
