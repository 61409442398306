"use client";

import {
  useContext,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { format } from "date-fns";
import { CalendarIcon, X } from "lucide-react";
import { toast } from "sonner";
import { Dialog, DialogClose } from "components/ui/dialog";
import { AppContext } from "contexts/AppContext";
import { TouchpointContext } from "contexts/TouchpointContext";
import { ContactsContext } from "contexts/ContactsContext";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const CalendarEvent = forwardRef(
  (
    { customerName, customerId, onClose, action_id },
    ref
  ) => {
    const { salesAgentCD, oktaId, logEvent, BASE_URL, token } =
      useContext(AppContext);
    const { addTouchpoint } = useContext(TouchpointContext);
    const { getPrimaryInfo } = useContext(ContactsContext);

    const [attendees, setAttendees] = useState([]);
    const [newAttendee, setNewAttendee] = useState("");
    const [selectedDate, setSelectedDate] = useState();
    const [startTime, setStartTime] = useState("12:00");
    const [endTime, setEndTime] = useState("12:30");
    const [title, setTitle] = useState("");
    const [meetingDetails, setMeetingDetails] = useState("");

    useEffect(() => {
      // Always try to fetch primary contact when component mounts or key values change
      const fetchPrimaryContact = async () => {
        if (customerId && salesAgentCD && oktaId) {
          const primaryInfoData = {
            custcd: customerId,
            salesagentcd: salesAgentCD,
            oktaid: oktaId,
          };

          const primaryContact = await getPrimaryInfo(primaryInfoData);
          if (primaryContact) {
            const primaryContactInfo = JSON.parse(
              primaryContact.primary_contact_info
            );
            if (primaryContactInfo.contact_email) {
              setNewAttendee(primaryContactInfo.contact_email);
              setAttendees([primaryContactInfo.contact_email]);
            }
          }
        }
      };

      fetchPrimaryContact();
    }, [customerId, salesAgentCD, oktaId, action_id]);

    const parseEmails = (input) => {
      const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
      return Array.from(input.matchAll(regex), (match) => match[1]);
    };

    const handleAddAttendee = (e) => {
      const inputValue = e.target.value;
      setNewAttendee(inputValue);
      const emails = parseEmails(inputValue);
      setAttendees(emails);
    };

    const removeAttendee = (attendeeToRemove) => {
      setAttendees(
        attendees.filter((attendee) => attendee !== attendeeToRemove)
      );
    };

    const handleSubmit = async () => {
      const startDateTime = selectedDate
        ? new Date(
            selectedDate.setHours(
              startTime.split(":")[0],
              startTime.split(":")[1]
            )
          )
        : null;
      const endDateTime = selectedDate
        ? new Date(
            selectedDate.setHours(endTime.split(":")[0], endTime.split(":")[1])
          )
        : null;

      const eventPayload = {
        oktaid: oktaId,
        salesagentcd: salesAgentCD,
        subject: title,
        content: meetingDetails,
        attendees_emails: attendees,
        start_time: startDateTime
          ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS")
          : null,
        end_time: endDateTime
          ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS")
          : null,
        timezone: "America/New_York",
      };

      try {
        const response = await axios.post(
          `${BASE_URL}/create-event`,
          eventPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // After successful calendar event creation, create a touchpoint
          const touchpointDate = selectedDate
            ? new Date(
                selectedDate.setHours(
                  startTime.split(":")[0],
                  startTime.split(":")[1]
                )
              ).toISOString()
            : new Date().toISOString();

          const formattedTouchpointDate =
            touchpointDate.slice(0, 19) +
            "." +
            touchpointDate.split(".")[1].padStart(3, "0") +
            "000";

          const currentDate = new Date().toISOString();
          const formattedDate =
            currentDate.slice(0, 19) +
            "." +
            currentDate.split(".")[1].padStart(3, "0") +
            "000";

          const metadata = {
            tp_type: "in-person",
            duration: null,
            num_participants: attendees.length || null,
            participants: attendees.length > 0 ? attendees : null,
            location: null,
            content: meetingDetails,
            disposition: null,
            notes: null,
            followup: null,
          };

          const touchpointData = {
            touchpointsid: uuidv4(),
            custcd: customerId,
            custname: customerName,
            tp_type: "in-person",
            summary: "Meeting Scheduled",
            touchpoint_date: formattedTouchpointDate,
            system_generated: false,
            visible: true,
            bookmarked: false,
            status: "scheduled",
            impact: 0,
            response_status: false,
            created_date: formattedDate,
            metadata: metadata,
          };

          await addTouchpoint(touchpointData);

          toast.success("Calendar Event and Touchpoint Created");
          console.log("Calendar event and touchpoint created");
          return true;
        } else {
          toast.error("Error creating event");
          return false;
        }
      } catch (error) {
        console.error("Error creating event:", error);
        toast.error("Error creating event");
      }

      if (typeof onClose === "function") {
        onClose();
      }
    };

    useImperativeHandle(ref, () => ({
      title,
      selectedDate,
      handleSubmit,
    }));

    return (
      <div className="w-full max-w-5xl mx-auto relative">
        <div className="">
          {!action_id && (
            <div className="">
              <div className="flex flex-col mb-6">
                <h2 className="text-lg sm:text-xl font-semibold">
                  Add Calendar Event
                </h2>
                <span className="text-base sm:text-md text-slate-500 dark:text-slate-500 font-light">
                  {customerName || customerId}
                </span>
              </div>
            </div>
          )}
          <div className="space-y-4 sm:space-y-6">
            {/* Title Input */}
            <div className="space-y-2">
              <Input
                placeholder="Add title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="text-base sm:text-lg font-semibold w-full"
              />
            </div>

            {/* Attendees */}
            <div className="space-y-2">
              <form onSubmit={handleAddAttendee} className="w-full">
                <Input
                  placeholder="Add attendees"
                  value={newAttendee}
                  onChange={handleAddAttendee}
                  type="email"
                  className="w-full"
                />
              </form>
              {/* <div className="flex flex-wrap gap-2 mt-2">
                {attendees.map((attendee) => (
                  <div
                    key={attendee}
                    className="flex items-center gap-1 bg-secondary px-2 py-1 rounded-full text-xs sm:text-sm"
                  >
                    <span>{attendee}</span>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-4 w-4"
                      onClick={() => removeAttendee(attendee)}
                    >
                      <X className="h-3 w-3" />
                      <span className="sr-only">Remove attendee</span>
                    </Button>
                  </div>
                ))}
              </div> */}
            </div>

            {/* Date and Time Selection */}
            <div className="space-y-4">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="w-full sm:w-1/2">
                  <Label className="mb-2">Date</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full justify-start text-left font-normal"
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {selectedDate
                          ? format(selectedDate, "PPP")
                          : "Select date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={selectedDate}
                        onSelect={setSelectedDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                <div className="w-full sm:w-1/2">
                  <Label className="mb-2">Time</Label>
                  <div className="flex gap-2 items-center">
                    <Select value={startTime} onValueChange={setStartTime}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Start time" />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from({ length: 24 }).map((_, i) => (
                          <SelectItem
                            key={i}
                            value={`${i.toString().padStart(2, "0")}:00`}
                          >
                            {`${i.toString().padStart(2, "0")}:00`}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <span className="text-sm text-gray-500">to</span>
                    <Select value={endTime} onValueChange={setEndTime}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="End time" />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from({ length: 24 }).map((_, i) => (
                          <SelectItem
                            key={i}
                            value={`${i.toString().padStart(2, "0")}:30`}
                          >
                            {`${i.toString().padStart(2, "0")}:30`}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </div>

            {/* Meeting Details */}
            <div className="space-y-2">
              <Label htmlFor="meeting-details">Meeting details</Label>
              <Textarea
                id="meeting-details"
                placeholder="Type details for this new meeting"
                className={`w-full ${
                  action_id ? "min-h-[42px] max-h-[42px]" : ""
                }`}
                value={meetingDetails}
                onChange={(e) => setMeetingDetails(e.target.value)}
              />
            </div>

            {/* Submit Button */}
            {!action_id && (
              <DialogClose asChild>
                <Button
                  className="w-full"
                  onClick={handleSubmit}
                  disabled={!title.trim() || !selectedDate}
                >
                  Create Event
                </Button>
              </DialogClose>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CalendarEvent;
