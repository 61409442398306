import React, { useEffect, useState, useContext }  from "react";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import DataTableViewOptions from "./data-table-view-options";
import DataTableFacetedFilter from "./data-table-faceted-filter";
import { CrossIcon, ListRestart } from "lucide-react";
import { AppContext } from "contexts/AppContext"

function DataTableToolbar({ table }) {

  const { logEvent } = useContext(AppContext)

  const isFiltered = table.getState().columnFilters.length > 0;

  const generateOptions = (columnData) => {
    const counts = {};

    columnData.forEach((rowValue) => {
      if (Array.isArray(rowValue)) {
        rowValue.forEach((item) => {
          counts[item] = (counts[item] || 0) + 1;
        });
      }
    });

    return Object.entries(counts).map(([value, count]) => ({
      value,
      label: `${value} (${count})`,
    }));
  };

  const insightPillsColumn = table.getColumn("insight_pills");
  const insightPillsOptions = insightPillsColumn
    ? generateOptions(insightPillsColumn.getFacetedRowModel().rows.map(row => row.getValue('insight_pills')))
    : [];

  const tagsColumn = table.getColumn("tags");
  const tagsOptions = tagsColumn
    ? generateOptions(tagsColumn.getFacetedRowModel().rows.map(row => row.getValue('tags')))
    : [];

  const [searchInput, setSearchInput] = useState('');
  const [longestSearch, setLongestSearch] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearchInput(newSearch);
    table.setGlobalFilter(newSearch); // Update the global filter immediately

    // Update longestSearch if the new search input is longer
    if (newSearch.length > longestSearch.length) {
      setLongestSearch(newSearch);
    }

    // Clear the existing debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer for 5 seconds
    const newTimer = setTimeout(() => {
      // Log the longest search string after 5 seconds of inactivity
      if (longestSearch) {
        logEvent('Customers', 'Search Input Logged', { search: longestSearch });
        console.log("Logged Search:", longestSearch);

        // Reset longest search for the next period
        setLongestSearch('');
      }
    }, 5000);

    // Update the debounce timer
    setDebounceTimer(newTimer);
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  return (
    <div className="flex flex-col space-y-4">
    {/* Search bar on its own line */}
    <div className="w-full">
      <Input
        placeholder="Search Customers..."
        value={table.getState().globalFilter || ""}
        onChange={handleSearchChange} 
        className="h-10 w-full border-2"
      />
    </div>
    <div className="flex flex-col lg:flex-row items-center justify-between space-y-2 lg:space-y-0 lg:space-x-2">
      <div className="flex flex-col lg:flex-row flex-1 items-center space-y-2 lg:space-y-0 lg:space-x-2 w-full">
        {/* <Input
          placeholder="Search Customers..."
          value={table.getState().globalFilter || ""}
          onChange={handleSearchChange} 
          className="h-8 w-full lg:w-[200px]"
        /> */}
        {insightPillsColumn && (
          <DataTableFacetedFilter
            column={insightPillsColumn}
            title="Insights"
            options={insightPillsOptions}
            className="w-full lg:w-auto"
          />
        )}
        {tagsColumn && (
          <DataTableFacetedFilter
            column={tagsColumn}
            title="Customer Details"
            options={tagsOptions}
            className="w-full lg:w-auto"
          />
        )}
        {(isFiltered || table.getState().globalFilter) && (
          <Button
            variant="ghost"
            onClick={() => {
              table.resetColumnFilters();
              table.setGlobalFilter("");
              logEvent("Customer Table","Reset Search",{});
            }}
            className="h-8 px-2 lg:px-3 w-full lg:w-auto"
          >
            Reset
            <CrossIcon className="ml-2 h-4 w-4" />
          </Button>
        )}
        <DataTableViewOptions table={table} />
      </div>
      <Button
        variant="outline"
        onClick={() => {
          table.resetSorting();
          table.resetColumnFilters();
          table.setGlobalFilter("");
          table.resetRowSelection();
          table.resetColumnVisibility();
          logEvent("Customer Table","Reset Filters and Search",{});
        }}
        className="h-8 px-2 lg:px-3 w-full lg:w-auto ml-auto"
      >
        <ListRestart className="mr-2 h-4 w-4" />
        Reset Table
      </Button>
    </div>
    </div>
  );
}

export default DataTableToolbar;