import React from 'react';
import { Title, Text } from '@tremor/react';
import MiniCalendar from './MiniCalendar';

const CalendarSidebar = ({ calendars, onToggleCalendar }) => {
  return (
    <div className="p-4">
      <MiniCalendar currentDate={new Date()} onDateSelect={() => {}} />
      <div className="mt-6">
        <Title className="text-sm mb-2">My calendars</Title>
        <div className="space-y-2">
          {calendars.map((calendar) => (
            <div key={calendar.id} className="flex items-center">
              <input
                type="checkbox"
                checked={calendar.enabled}
                onChange={() => onToggleCalendar(calendar.id)}
                className="mr-2"
              />
              <Text>{calendar.name}</Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarSidebar; 