import React from 'react';
import {
  Card,
  Title,
  Text,
  Button,
  TextInput,
  DatePicker,
  Select,
  SelectItem,
} from '@tremor/react';
import { X } from "lucide-react";

const NewEventModal = ({ isOpen, setIsOpen }) => {
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
      <Card className="w-[600px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <Title>New event</Title>
          <Button icon={X} variant="light" onClick={() => setIsOpen(false)} />
        </div>
        
        <div className="space-y-4">
          <div>
            <Text className="mb-2">Title</Text>
            <TextInput placeholder="Add a title" className="w-full" />
          </div>

          <div className="flex gap-4">
            <div className="flex-1">
              <Text className="mb-2">Start</Text>
              <DatePicker className="w-full" />
              <Select className="mt-2" defaultValue="5:00 PM">
                <SelectItem value="5:00 PM">5:00 PM</SelectItem>
                <SelectItem value="5:30 PM">5:30 PM</SelectItem>
                <SelectItem value="6:00 PM">6:00 PM</SelectItem>
              </Select>
            </div>
            <div className="flex-1">
              <Text className="mb-2">End</Text>
              <DatePicker className="w-full" />
              <Select className="mt-2" defaultValue="5:30 PM">
                <SelectItem value="5:30 PM">5:30 PM</SelectItem>
                <SelectItem value="6:00 PM">6:00 PM</SelectItem>
                <SelectItem value="6:30 PM">6:30 PM</SelectItem>
              </Select>
            </div>
          </div>

          <div>
            <Text className="mb-2">Location</Text>
            <TextInput placeholder="Add a location" className="w-full" />
          </div>

          <div>
            <Text className="mb-2">Description</Text>
            <TextInput placeholder="Add a description" className="w-full" />
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary">
              Save
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewEventModal; 