import React from 'react';
import { Tab, TabGroup, TabList } from '@tremor/react';

const ViewSelector = ({ currentView, onViewChange }) => {
  return (
    <div className="flex items-center space-x-2">
      <TabGroup index={['day', 'work-week', 'week', 'month'].indexOf(currentView)} onIndexChange={(index) => onViewChange(['day', 'work-week', 'week', 'month'][index])}>
        <TabList variant="solid">
          <Tab>Day</Tab>
          <Tab>Work week</Tab>
          <Tab>Week</Tab>
          <Tab>Month</Tab>
        </TabList>
      </TabGroup>
    </div>
  );
};

export default ViewSelector; 