import React, { useState } from 'react';
import {
  Button,
  Select,
  SelectItem,
  Flex,
} from '@tremor/react';
import { Plus, Share2, Printer } from "lucide-react";
import NewEventModal from './NewEventModal';

const CalendarTopHeader = () => {
  const [isNewEventOpen, setIsNewEventOpen] = useState(false);

  return (
    <>
      <Flex justifyContent="between" className="flex-col sm:flex-row gap-2 sm:gap-0">
        <div className="flex items-center space-x-2 w-full sm:w-auto overflow-x-auto">
          <Button 
            size="sm" 
            variant="primary" 
            icon={Plus}
            onClick={() => setIsNewEventOpen(true)}
          >
            New event
          </Button>
          <Select defaultValue="day" className="w-24 min-w-[96px]">
            <SelectItem value="day">Day</SelectItem>
            <SelectItem value="work-week">Work week</SelectItem>
            <SelectItem value="week">Week</SelectItem>
            <SelectItem value="month">Month</SelectItem>
          </Select>
          <Select defaultValue="all" className="w-32 min-w-[128px]">
            <SelectItem value="all">All Events</SelectItem>
            <SelectItem value="meetings">Meetings</SelectItem>
            <SelectItem value="work">Work</SelectItem>
            <SelectItem value="personal">Personal</SelectItem>
          </Select>
        </div>

        <div className="flex items-center sm:ml-auto">
          <div className="inline-flex">
            <Button size="xs" variant="secondary" icon={Share2}>Share</Button>
            <Button size="xs" variant="secondary" className="ml-2" icon={Printer}>Print</Button>
          </div>
        </div>
      </Flex>

      <NewEventModal isOpen={isNewEventOpen} setIsOpen={setIsNewEventOpen} />
    </>
  );
};

export default CalendarTopHeader; 