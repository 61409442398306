import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Card, CardContent } from "components/ui/card";
import { Input } from "components/ui/input";
import { Search } from 'lucide-react';
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import Dashboard from './components/Dashboard';
import CustomerJourneyMap from './components/CustomerJourneyMap';
import RecentTouchpoints from './components/RecentTouchpoints';
import AddTouchpoint from './components/AddTouchpoint';
import CustomerProfile from './components/CustomerProfile';
import TouchpointsList from './components/TouchpointsList';
import { SalesAnalysisChart } from './components/SalesChart';

const TouchpointSystemHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="w-full">
      <div className="flex flex-col gap-6">
        <Card className="w-full">
          <CardContent className="p-0">
            <Tabs defaultValue="sales" className="w-full">
              <div className="border-b">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <ScrollArea className="w-full md:w-auto" orientation="horizontal">
                    <div className="flex w-max p-2">
                      <TabsList className="h-auto inline-flex bg-muted rounded-full p-1 gap-1">
                        {/* <TabsTrigger 
                          value="journey" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Journey
                        </TabsTrigger> */}
                        <TabsTrigger 
                          value="sales"
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Sales
                        </TabsTrigger>
                        <TabsTrigger 
                          value="interactions" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Touchpoints
                        </TabsTrigger>
                        <TabsTrigger 
                          value="new" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Add Touchpoint
                        </TabsTrigger>
                      </TabsList>
                    </div>
                    <ScrollBar orientation="horizontal" className="h-2.5 bg-muted" />
                  </ScrollArea>
                </div>
              </div>
              <div className="p-2 sm:p-4">
                <TabsContent value="journey" className="m-0">
                  <CustomerJourneyMap />  
                </TabsContent>
                <TabsContent value="touchpoints" className="m-0">
                  <TouchpointsList />
                </TabsContent>
                <TabsContent value="analytics" className="m-0">
                  <Dashboard />
                </TabsContent>
                <TabsContent value="interactions" className="m-0">
                  <RecentTouchpoints searchTerm={searchTerm} />
                </TabsContent>
                <TabsContent value="sales" className="m-0">
                  <SalesAnalysisChart />
                </TabsContent>
                <TabsContent value="new" className="m-0">
                  <AddTouchpoint />
                </TabsContent>
                <TabsContent value="profile" className="m-0">
                  <CustomerProfile />
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TouchpointSystemHome;