import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'next-themes';
import logo from 'img/logo1.png';
import logo_mini from 'img/logo_mini.png';
import logo_srs from 'img/srs.png';
import powered from 'img/cosailor2.png';
import srs_cosailor from 'img/cosailor1.png'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarSection,
  SidebarSpacer,
  SidebarItem,
  SidebarLabel,
} from 'components/catalyst/sidebar';
import { Divider } from 'components/catalyst/divider';
import { menuItems } from './MenuItems';
import { AccountSelect } from './AccountSelect';
import { UserDropdown } from './UserDropdown';
import { PanelLeft } from 'lucide-react';

export function SidebarContent({ closeSidebar, isMini = false, toggleMini = () => {} }) {
  const { theme } = useTheme();
  const location = useLocation();

  return (
    <Sidebar
      className={`transition-all duration-300 ease-in-out ${isMini ? 'w-18' : 'w-64'}`}
    >
      {/* Header Section */}
      <SidebarHeader>
        <SidebarSection>
        <div className="flex w-full items-center justify-between">
          {isMini ? (
            // Mini Mode
            <div className="flex flex-col items-center">
              <div className="flex w-full items-center justify-between">
                {/* <Link to="/" onClick={closeSidebar}>
                  <img
                    src={logo_mini}
                    className={`py-2 ${theme === "dark" ? "invert" : ""} w-6`}
                    alt="Mini Logo"
                  />
                </Link> */}
                <div></div>
                <button
                  onClick={toggleMini}
                  className="text-gray-900 hover:text-gray-900 pt-3 self-start "
                >
                  <PanelLeft className="w-4 h-4" />
                </button>
              </div>
              <Link to="/" onClick={closeSidebar}>
                <img
                  src={logo_srs}
                  className={`py-2 ${theme === "dark" ? "invert" : ""} h-[60px]`}
                  alt="SRS Logo"
                />
              </Link>
            </div>
          ) : (
            // Expanded Mode
            <div className="w-full">
              <div className="flex w-full items-center justify-between">
                <Link to="/" onClick={closeSidebar}>
                  <img
                    src={logo_srs}
                    className={`py-2 ${theme === "dark" ? "invert" : ""} w-[120px]`}
                    alt="SRS Logo"
                  />
                </Link>
                <button
                  onClick={toggleMini}
                  className="text-gray-900 hover:text-gray-900 pt-3 self-start max-lg:hidden"
                >
                  <PanelLeft className="w-4 h-4" />
                </button>
              </div>
            <div className="font-bold">Cosailor BETA</div>
            </div>
          )}
        </div>
        </SidebarSection>
      </SidebarHeader>

      {/* Body Section */}
      <SidebarBody>
        <SidebarSection>
          <div className={`${isMini ? 'flex flex-col items-center gap-1' : 'flex flex-col gap-1'}`}>
            {menuItems.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <SidebarItem
                  as={Link}
                  to={item.path}
                  key={item.path}
                  onClick={closeSidebar}
                  current={isActive}
                >
                 <item.icon style={{ width: '22px', height: '22px' }} />
                  {!isMini && (
                    <SidebarLabel className="text-[14px]">
                      {item.label}
                    </SidebarLabel>
                  )}
                </SidebarItem>
              );
            })}
          </div>
        </SidebarSection>
        {!isMini && 
          <>
            <Divider className="mt-4"/>
            <AccountSelect />
          </>
        }
        <SidebarSpacer />
      </SidebarBody>
      {/* <SidebarSpacer /> */}
      {!isMini &&
      <a href="https://www.instalily.ai" target="_blank" rel="noopener noreferrer">
        <div className="w-full flex items-center justify-center">
          <img
            src={powered}
            className={`py-2 ${theme === "dark" ? "invert" : ""} w-[180px]`}
            alt="Instalily Logo"
          />
        </div>
      </a>
      }
      <SidebarFooter className="max-lg:hidden">
        <UserDropdown closeSidebar={closeSidebar} isMini={isMini} />
      </SidebarFooter>
    </Sidebar>
  );
}
