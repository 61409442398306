import React, { useContext, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Card, CardContent } from "components/ui/card";
import { Input } from "components/ui/input";
import { Search, Plus } from "lucide-react";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import { Button } from "components/ui/button";
import Dashboard from "./components/Dashboard";
import CustomerJourneyMap from "./components/CustomerJourneyMap";
import RecentTouchpoints from "./components/RecentTouchpoints";
import AddTouchpoint from "./components/AddTouchpoint";
import CustomerProfile from "./components/CustomerProfile";
import TouchpointsList from "./components/TouchpointsList";
import { CustomerDetails } from "pages/customer-template/CustomerDetails";
import { AppContext } from "contexts/AppContext";
import ContactDetails from "./components/ContactDetails";

const TouchpointSystem = ({ custcd, custname, isCompactView }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { salesagentcd, oktaid } = useContext(AppContext)

  return (
    <div className="w-full">
      <div className="flex flex-col gap-6">
        <Card className="w-full">
          <CardContent className="p-0">
            <Tabs defaultValue="details" className="w-full">
              <div className="border-b">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <ScrollArea className="w-full md:w-auto" orientation="horizontal">
                    <div className="flex w-max p-2">
                      <TabsList className="h-auto inline-flex bg-muted rounded-full p-1 gap-1">
                        <TabsTrigger
                          value="details"
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Contact Details
                        </TabsTrigger>
                        <TabsTrigger
                          value="new"
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Add Touchpoint
                        </TabsTrigger>
                        <TabsTrigger
                          value="interactions"
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Touchpoints
                        </TabsTrigger>
                      </TabsList>
                    </div>
                    <ScrollBar orientation="horizontal" className="h-2.5 bg-muted" />
                  </ScrollArea>
                </div>
              </div>
              <div className="p-2 sm:p-4">
                <TabsContent value="journey" className="m-0">
                  <CustomerJourneyMap />
                </TabsContent>
                <TabsContent value="touchpoints" className="m-0">
                  <TouchpointsList
                    custcd={custcd}
                    isCompactView={isCompactView}
                  />
                </TabsContent>
               <TabsContent value="details" className="m-0">
                 {/* <CustomerDetails custcd={custcd} /> */}
                 <ContactDetails custcd={custcd} salesagentcd={salesagentcd} oktaid={oktaid}/>
               </TabsContent>
                <TabsContent value="analytics" className="m-0">
                  <Dashboard />
                </TabsContent>
                <TabsContent value="new" className="m-0">
                  <AddTouchpoint custcd={custcd} custname={custname} />
                </TabsContent>
                <TabsContent value="interactions" className="m-0">
                  <RecentTouchpoints
                    searchTerm={searchTerm}
                    custcd={custcd}
                    isCompactView={isCompactView}
                  />
                </TabsContent>
                <TabsContent value="profile" className="m-0">
                  <CustomerProfile />
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TouchpointSystem;