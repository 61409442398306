import React, { useContext, useState } from 'react';
import { Drawer, DrawerTrigger, DrawerContent } from 'components/ui/drawer';
import { ChatContext } from 'contexts/ChatContext';
import { useTheme } from 'next-themes'; 
import ChatWindow from './chatWindow';
import Input from './components/Input';
import { IconButton, Tooltip } from '@mui/material';
import { Sailboat } from 'lucide-react';
import './styles/Layout.css';
import './styles/Themes.css';
import ProgressCircle from './components/Progress';

const MobileChatLayout = ({ open, handleToggle }) => {
  const { typingIndicator } = useContext(ChatContext);
  const { theme } = useTheme(); 
  const [inputFocused, setInputFocused] = useState(false);

  const iconColor = theme === 'dark' ? '#FFFFFF' : '#000000'; 

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Tooltip title="Toggle Chat">
          <IconButton
            color="secondary"
            variant="light"
            sx={{
              color: iconColor,
              bgcolor: open ? 'grey.100' : 'transparent',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4px',
              gap: '2px'
            }}
            aria-label="settings toggler"
          >
            <Sailboat className="w-6 h-6" style={{ color: iconColor }} />
            <span className="text-xs" style={{ color: iconColor }}>
              Chat
            </span>
          </IconButton>
        </Tooltip>
      </DrawerTrigger>
      <DrawerContent className="h-[70vh] flex flex-col bg-[#f6f6f6] dark:bg-[#1e1e1e]">
        <div id="pixel-container">
          <div className="vsa-chat-box-container">
            <div className="vsa-window">
              <ChatWindow />
            </div>
            {typingIndicator && <ProgressCircle />}
            {!typingIndicator && (
              <Input
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
              />
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileChatLayout;