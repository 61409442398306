import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import {
  BellDot,
  UserIcon,
  HomeIcon,
  ZoomInIcon,
  Settings2Icon,
  BookText,
  Podcast,
  CalendarPlus,
} from "lucide-react";
import { Heading } from "components/catalyst/heading";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import Cosailor from "components/chat";
import { Link, useLocation } from "react-router-dom";
import { menuItems } from "./MenuItems";
import { DataContext } from "contexts/DataContext";
import { NotificationDropdown } from "components/catalyst/notifications";
import { CustomerSettings } from "components/catalyst/customer-settings";
import AudioPlayerPopup from "components/podcast/podcast";
import PodcastPlayer from "components/podcast/podcast";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import CalendarEvent from "../../pages/customer-template/CalendarEvent";
import { useTheme } from "next-themes";

// Import the audio file
import daveJoyceAudio from "../podcast/dave_joyce_2024-09-25.wav";
import matthewPariseAudio from "../podcast/matthew_parise_2024-09-25.wav";
import robertMarinoAudio from "../podcast/robert_marino_2024-09-25.wav";
import jasonSalvinoAudio from "../podcast/jason_salvino_2024-10-04.wav";
import johnDemoAudio from "../podcast/john_demo.wav";

import { AppContext } from "contexts/AppContext";
import { ChatContext } from "contexts/ChatContext";

export const Header = () => {
  const location = useLocation();
  const { customerProfilePage, podcastURL } = useContext(DataContext);
  const { user, salesAgentCD } = useContext(AppContext);
  const { setCurrentCustomer } = useContext(ChatContext);

  const [title, setTitle] = useState("");
  const [isPodcastPlayerOpen, setIsPodcastPlayerOpen] = useState(false);

  const togglePodcastPlayer = () => {
    setIsPodcastPlayerOpen(!isPodcastPlayerOpen);
  };

  // Find the current menu item based on the location path
  const currentMenuItem =
    menuItems.find((item) => item.path === location.pathname) || {};

  // Check if the current path matches "/customer/:id"
  const customerPathMatch = location.pathname.match(/^\/customer\/(.+)/);
  const customerId = customerPathMatch ? customerPathMatch[1] : null;

  useEffect(() => {
    if (customerId) {
      setTitle(customerProfilePage.custname || "");
    } else {
      setTitle(currentMenuItem.page_title || "");
      setCurrentCustomer(null);
    }
  }, [customerProfilePage, currentMenuItem.page_title, location.pathname]);

  const { theme } = useTheme();
  const iconColor = theme === "dark" ? "#FFFFFF" : "#000000";

  return (
    <header>
      <div>
        <Breadcrumb>
          <BreadcrumbList className="flex items-center">
            <Link to="/">
              <BreadcrumbItem className="flex items-center">
                <HomeIcon className="w-3.5" />
                <BreadcrumbPage>Home</BreadcrumbPage>
              </BreadcrumbItem>
            </Link>
            {customerId ? (
              <>
                <BreadcrumbSeparator />
                <Link to={`/customer/${customerId}`}>
                  <BreadcrumbItem className="flex items-center">
                    <UserIcon className="w-3.5" />
                    <BreadcrumbPage>Customer Profile</BreadcrumbPage>
                  </BreadcrumbItem>
                </Link>
                <BreadcrumbSeparator />
                <Link to={`/customer/${customerId}`}>
                  <BreadcrumbItem className="flex items-center">
                    <ZoomInIcon className="w-3.5" />
                    <BreadcrumbPage>{customerId}</BreadcrumbPage>
                  </BreadcrumbItem>
                </Link>
              </>
            ) : location.pathname === "/profile" ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <UserIcon className="w-3.5" />
                  <BreadcrumbPage>My Profile</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : location.pathname === "/feedback" ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <Settings2Icon className="w-3.5" />
                  <BreadcrumbPage>Feedback</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : (
              location.pathname !== "/" && (
                <>
                  <BreadcrumbSeparator />
                  <Link to={currentMenuItem.path}>
                    <BreadcrumbItem>
                      {currentMenuItem.icon && (
                        <currentMenuItem.icon className="w-3.5" />
                      )}
                      <BreadcrumbPage>
                        {currentMenuItem.page_title}
                      </BreadcrumbPage>
                    </BreadcrumbItem>
                  </Link>
                </>
              )
            )}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>{title}</Heading>
        <div className="flex items-center ml-auto space-x-1">
          {customerId && (
            <Dialog modal>
              <DialogTrigger asChild>
                <button className="flex flex-col items-center gap-0.5 px-2 py-1 
                          hover:bg-gray-100 rounded-md transition">
                  <CalendarPlus className="w-6 h-6" />
                  <span className="text-xs" style={{ color: iconColor }}>
                    Add Event
                  </span>
                </button>
              </DialogTrigger>
              <DialogContent
                onPointerDownOutside={(e) => e.preventDefault()}
                className="sm:max-w-[425px]"
              >
                <CalendarEvent
                  customerName={customerProfilePage.custname}
                  customerId={customerId}
                />
              </DialogContent>
            </Dialog>
          )}
          {podcastURL && (
            <button
              onClick={togglePodcastPlayer}
              aria-label="Podcast"
              className={`flex flex-col items-center gap-0.5 px-2 py-1 
                          hover:bg-gray-100 rounded-md transition`}
            >
              <Podcast />
              <span className="text-xs" style={{ color: iconColor }}>
                Podcast
              </span>
            </button>
          )}
          <Link
            to="/feedback"
            aria-label="Feedback"
            className="flex flex-col items-center gap-0.5 px-2 py-1 
                      hover:bg-gray-100 rounded-md transition"
          >
            <BookText />
            <span className="text-xs" style={{ color: iconColor }}>
              Feedback
            </span>
          </Link>
          <Popover>
            <PopoverTrigger asChild>
              <button
                className="flex flex-col items-center gap-0.5 px-2 py-1 
                        hover:bg-gray-100 rounded-md transition"
                aria-label="Notifications"
              >
                <BellDot className="w-6 h-6" />
                <span className="text-xs" style={{ color: iconColor }}>
                  Updates
                </span>
              </button>
            </PopoverTrigger>
            <PopoverContent>
              <NotificationDropdown />
            </PopoverContent>
          </Popover>
          <Cosailor />
        </div>
      </div>
      {isPodcastPlayerOpen && (
        <PodcastPlayer
          audioSrc={podcastURL}
          onClose={() => setIsPodcastPlayerOpen(false)}
        />
      )}
    </header>
  );
};
