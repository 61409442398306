import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);

  if (testing) return children
  
  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM5NDAyNzU4LCJleHAiOjE3Mzk0MDYzNTgsImp0aSI6IklELmpuZ0FUZDB1SUlUVzZaempxZlhXcmF3eThSeE9JWDlNSnJabm1JX25yWDgiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJxRXR0bEZNcThxejJ1eVE3ODVUWmdkQXUyN1RwTEZZSlE1RjhPb3hkVkNMallLeWRzQUhEM2djUWRPZ3FPSDQ5IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTczOTM3NDE5MCwiYXRfaGFzaCI6IkluZkZYVi1uOWNYVFk2TUNyandEVFEifQ.HC42a_B4EvMKkiv9UgReimgeSApxufVy7a2I2i2sVEXkJGt3awDoqdSyAh6mUSLq7kRBzqa9Q4hNl4h32GKGqa-Rk7l51ormCNrBrnK9D5ml2QarFt89uDmrsaoDsZW1PeuenyEYbcZoNbrEEIknLFoMZyexNj1LYcDEujBeMfKl486OP_WNIiMlOk_xPjhTYTW0epLy3jdEa0fyAqILmjA_5fkQdGU4tpO2YsySt3x-CtdmCTueNNNwKgru6UMYlAWv7FjLQvYSbh1vi3tQE8IjZ5fszHNFdGFcQUf3UW8RJs9bPvAZXRiTLNN7esGw91t6sQwblQo5sAtHSMi3EA")) {
    return children;
  }
  
  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const initToken = async () => {
        try {
          await oktaAuth.tokenManager.renew('idToken');
          const tokenResponse = await oktaAuth.tokenManager.get('idToken');
          
          if (!tokenResponse?.value) {
            console.error('No valid token value');
            return;
          }
  
          localStorage.setItem('okta-token-storage', JSON.stringify({
            idToken: {
              idToken: tokenResponse.value,
              claims: tokenResponse.claims,
              expiresAt: tokenResponse.expiresAt
            }
          }));
        } catch (error) {
          console.error('Token initialization failed:', error);
          oktaAuth.signOut();
        }
      };
      initToken();
    }
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated ) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
