import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { Badge } from "components/ui/badge";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { TouchpointContext } from "contexts/TouchpointContext";
import { AppContext } from "contexts/AppContext";
import { ArrowRightCircle } from "lucide-react";

const ViewDetailsDialog = ({ touchpoint, onClose, onUpdate, onDelete }) => {
  const [updatedStatus, setUpdatedStatus] = useState(touchpoint.status || "");
  const [selectedDisposition, setSelectedDisposition] = useState(
    touchpoint.metadata.disposition || "neutral"
  );
  const [followupText, setFollowupText] = useState(
    touchpoint.metadata.followup || ""
  );

  useEffect(() => {
    setUpdatedStatus(touchpoint.status || "");
    setSelectedDisposition(touchpoint.metadata.disposition || "neutral");
    setFollowupText(touchpoint.metadata.followup || "");
  }, [touchpoint]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the metadata based on tp_type
    let metadata;

    switch (touchpoint.tp_type) {
      case "email":
        metadata = {
          tp_type: "email",
          subject: touchpoint.metadata.subject || "",
          ccs: touchpoint.metadata.ccs || [],
          bccs: touchpoint.metadata.bccs || [],
          num_attachments: touchpoint.metadata.num_attachments || 0,
          senders: touchpoint.metadata.senders || [],
          recipients: touchpoint.metadata.recipients || [],
          content: touchpoint.metadata.content,
          disposition: selectedDisposition || "neutral",
          notes: touchpoint.metadata.notes || "",
          followup: followupText || touchpoint.metadata.followup,
        };
        break;
      case "call":
        metadata = {
          tp_type: "call",
          participants: touchpoint.metadata.participants || [],
          duration: touchpoint.metadata.duration || 0,
          num_participants: touchpoint.metadata.num_participants || 0,
          has_recording: touchpoint.metadata.has_recording || false,
          content: touchpoint.metadata.content,
          disposition: selectedDisposition || "neutral",
          notes: touchpoint.metadata.notes || "",
          followup: followupText || touchpoint.metadata.followup,
        };
        break;
      case "text":
        metadata = {
          tp_type: "text",
          senders: touchpoint.metadata.senders || [],
          recipients: touchpoint.metadata.recipients || [],
          content: touchpoint.metadata.content,
          disposition: selectedDisposition || "neutral",
          notes: touchpoint.metadata.notes || "",
          followup: followupText || touchpoint.metadata.followup,
        };
        break;
      case "in-person":
        metadata = {
          tp_type: "in-person",
          duration: touchpoint.metadata.duration || 0,
          num_participants: touchpoint.metadata.num_participants || 0,
          participants: touchpoint.metadata.participants || [],
          location: touchpoint.metadata.location || "",
          content: touchpoint.metadata.content,
          disposition: selectedDisposition || "neutral",
          notes: touchpoint.metadata.notes || "",
          followup: followupText || touchpoint.metadata.followup,
        };
        break;
      default:
        metadata = {};
        break;
    }

    // Construct the full payload
    const payload = {
      ...touchpoint,
      status: updatedStatus || touchpoint.status,
      updated_date: new Date().toISOString(),
      metadata: metadata,
      custcd: touchpoint.custcd,
    };

    console.log("Payload:", payload);

    try {
      await onUpdate(payload);
      console.log("Touchpoint successfully updated");
      onClose();
    } catch (err) {
      console.error("Error updating touchpoint:", err);
    }
  };

  const handleDelete = async () => {
    try {
      const payload = {
        ...touchpoint,
        updated_date: new Date().toISOString(),
      };
      await onDelete(payload);
      console.log("Touchpoint successfully deleted");
      onClose();
    } catch (err) {
      console.error("Error deleting touchpoint:", err);
    }
  };

  return (
    <DialogContent className="sm:max-w-[600px]">
      <DialogHeader>
        <DialogTitle>
          <Link
            to={`/customer/${touchpoint.custcd}`}
            className="hover:underline transition-all duration-200 font-semibold"
          >
            {touchpoint.custname}
          </Link>
        </DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="content">Touchpoint Details</Label>
          <div className="text-gray-700 text-sm max-h-[200px] overflow-y-auto border border-gray-200 rounded-md p-4">
            {touchpoint.tp_type === "email" ? (
              <>
                {touchpoint.metadata.subject && (
                  <div className="mb-4">
                    Subject: {touchpoint.metadata.subject}
                  </div>
                )}
                <div className="whitespace-pre-line">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: touchpoint.metadata.content,
                    }}
                  />
                </div>
              </>
            ) : touchpoint.tp_type === "in-person" ? (
              <div className="whitespace-pre-line">
                {touchpoint.metadata.content.trim()}
              </div>
            ) : (
              touchpoint.metadata.content
            )}
          </div>
        </div>

        <div className="space-y-2 gap-2">
          <Label>Client Disposition</Label>
          <div className="flex gap-4 items-center">
            <div className="flex items-center">
              <input
                type="radio"
                id="negative"
                name="disposition"
                value="negative"
                checked={selectedDisposition === "negative"}
                onChange={() => setSelectedDisposition("negative")}
                className="h-4 w-4"
              />
              <label htmlFor="negative" className="ml-2">
                negative
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="neutral"
                name="disposition"
                value="neutral"
                checked={selectedDisposition === "neutral"}
                onChange={() => setSelectedDisposition("neutral")}
                className="h-4 w-4"
              />
              <label htmlFor="neutral" className="ml-2">
                neutral
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="positive"
                name="disposition"
                value="positive"
                checked={selectedDisposition === "positive"}
                onChange={() => setSelectedDisposition("positive")}
                className="h-4 w-4"
              />
              <label htmlFor="positive" className="ml-2">
                positive
              </label>
            </div>
          </div>
        </div>

        <div className="space-y-2 gap-2">
          <Label>Status</Label>
          <div className="flex gap-4 items-center">
            <div className="flex items-center">
              <input
                type="radio"
                id="completed"
                name="status"
                value="completed"
                checked={updatedStatus === "completed"}
                onChange={() => setUpdatedStatus("completed")}
                className="h-4 w-4"
              />
              <label htmlFor="completed" className="ml-2">
                completed
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="scheduled"
                name="status"
                value="scheduled"
                checked={updatedStatus === "scheduled"}
                onChange={() => setUpdatedStatus("scheduled")}
                className="h-4 w-4"
              />
              <label htmlFor="scheduled" className="ml-2">
                scheduled
              </label>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="followup">Follow-up Notes</Label>
          <Input
            id="followup"
            value={followupText}
            onChange={(e) => setFollowupText(e.target.value)}
            placeholder="Add any follow-up notes..."
            className="w-full"
          />
        </div>

        <div className="flex space-x-2">
          <Button
            className="w-full"
            type="button"
            variant="outline"
            onClick={handleDelete}
          >
            Delete Touchpoint
          </Button>
          <Button
            className="w-full"
            type="submit"
            disabled={!selectedDisposition || !updatedStatus}
          >
            Update Touchpoint
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

const RecentTouchpoints = ({ custcd, isCompactView = false }) => {
  const { touchpoints, updateTouchpoint, deleteTouchpoint } =
    useContext(TouchpointContext);
  const { isMobile } = useContext(AppContext);
  const [openDialogId, setOpenDialogId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 6); // -6 to include today in the 7-day range

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredTouchpoints = touchpoints.filter((touchpoint) => {
    // Create date object from touchpoint date and adjust to local date
    const touchpointDate = new Date(touchpoint.touchpoint_date);
    const localTouchpointDate = new Date(
      touchpointDate.getFullYear(),
      touchpointDate.getMonth(),
      touchpointDate.getDate()
    );

    // Create start date object adjusted to local date
    const startDateObj = startDate ? new Date(startDate + "T00:00:00") : null;
    // Create end date object adjusted to local date
    const endDateObj = endDate ? new Date(endDate + "T23:59:59") : null;

    const matchesSearch = isCompactView
      ? true
      : touchpoint.custname.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDateRange = isCompactView
      ? true
      : (!startDateObj || localTouchpointDate >= startDateObj) &&
        (!endDateObj || localTouchpointDate <= endDateObj);
    const matchesCustomer = custcd ? touchpoint.custcd === custcd : true;

    return matchesSearch && matchesDateRange && matchesCustomer;
  });

  return (
    <Card>
      {!isCompactView && (
        <CardHeader>
          <div
            className={`flex ${
              isMobile
                ? "flex-col items-center"
                : "flex-wrap items-center justify-between"
            } gap-2`}
          >
            <Input
              type="text"
              placeholder="Search by customer name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-[250px]"
            />
            {isMobile ? (
              <div className="flex flex-col items-center">
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-auto"
                />
                <span className="text-sm text-muted-foreground">to</span>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-auto"
                />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-auto"
                />
                <span className="text-sm text-muted-foreground">to</span>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-auto"
                />
              </div>
            )}
          </div>
        </CardHeader>
      )}
      <CardContent>
        <ScrollArea className="h-[calc(100vh-200px)] max-h-[440px]">
          <div className="overflow-x-auto -mx-4 sm:mx-0">
            <Table>
              <TableHeader>
                <TableRow>
                  {!isCompactView && (
                    <TableHead className="w-[25%] xl:w-[20%] text-center p-4">
                      Customer
                    </TableHead>
                  )}
                  <TableHead
                    className={`${
                      isCompactView
                        ? "w-[40%] xl:w-[40%] lg:w-[25%]"
                        : "w-[45%] xl:w-[20%]"
                    } ${
                      !isCompactView && isMobile ? "hidden" : ""
                    } text-center p-4`}
                  >
                    Summary
                  </TableHead>
                  <TableHead
                    className={`${
                      isCompactView
                        ? "w-[60%] sm:table-cell xl:hidden hidden lg:w-[37.5%]"
                        : "hidden xl:table-cell w-[30%]"
                    } text-center p-4`}
                  >
                    Content
                  </TableHead>
                  <TableHead
                    className={`${
                      isCompactView
                        ? "w-[40%] xl:w-[20%] lg:w-[25%]"
                        : "w-[25%] xl:w-[25%]"
                    } text-center p-4`}
                  >
                    Date
                  </TableHead>
                  <TableHead
                    className={`${
                      isCompactView ? "w-[5%]" : "w-[5%]"
                    } text-center p-4`}
                  ></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredTouchpoints.map((touchpoint) => (
                  <TableRow
                    key={touchpoint.touchpointsid}
                    className="hover:bg-gray-50"
                  >
                    {!isCompactView && (
                      <TableCell className="w-[25%] xl:w-[20%] text-center p-4">
                        <Link
                          to={`/customer/${touchpoint.custcd}`}
                          className="hover:underline"
                        >
                          {touchpoint.custname}
                        </Link>
                      </TableCell>
                    )}

                    <TableCell
                      className={`${
                        isCompactView
                          ? "w-[40%] xl:w-[40%] lg:w-[25%]"
                          : "w-[45%] xl:w-[20%]"
                      } ${
                        !isCompactView && isMobile ? "hidden" : ""
                      } text-center p-4`}
                    >
                      {touchpoint.summary}
                    </TableCell>
                    <TableCell
                      className={`${
                        isCompactView
                          ? "w-[60%] sm:table-cell xl:hidden hidden lg:w-[37.5%]"
                          : "hidden xl:table-cell w-[30%]"
                      } text-center p-4`}
                    >
                      {touchpoint.metadata?.content
                        ?.replace(/<[^>]*>/g, " ")
                        .replace(/\s+/g, " ")
                        .trim()
                        .slice(0, 100)}
                      {touchpoint.metadata?.content?.length > 100 ? "..." : ""}
                    </TableCell>

                    <TableCell
                      className={`${
                        isCompactView
                          ? "w-[40%] xl:w-[20%] lg:w-[25%]"
                          : "w-[25%] xl:w-[25%]"
                      } text-center p-4 whitespace-nowrap`}
                    >
                      {new Date(
                        touchpoint.touchpoint_date
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      className={`${
                        isCompactView ? "w-[5%]" : "w-[5%]"
                      } text-center p-4`}
                    >
                      <Dialog 
                        open={openDialogId === touchpoint.touchpointsid}
                        onOpenChange={(isOpen) => {
                          setOpenDialogId(isOpen ? touchpoint.touchpointsid : null);
                        }}
                      >
                        <DialogTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-8 w-8 hover:bg-gray-100"
                          >
                            <ArrowRightCircle className="h-4 w-4" />
                          </Button>
                        </DialogTrigger>
                        <ViewDetailsDialog
                          touchpoint={touchpoint}
                          onClose={() => setOpenDialogId(null)}
                          onUpdate={updateTouchpoint}
                          onDelete={deleteTouchpoint}
                        />
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default RecentTouchpoints;
