import React, { useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerProfile } from './CustomerProfile';
import { KPIs } from './KPIs';
import { Insights } from './Insights';
import { ConnectButtons, CustomerDetails } from './CustomerDetails';
import { MinimumSpendPotential } from './MinimumSpendPotential';
import { CustomerReminders } from './Reminders';
import { Purchases } from './Purchases';
import { Notes } from './Notes';
import { SendEmail } from './SendEmail';
import { CustomerSalesTable } from './SalesTable';
import { DataContext } from 'contexts/DataContext';
import { AppContext } from 'contexts/AppContext';
import { NotesContext } from 'contexts/NotesContext';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { ScrollArea } from 'components/ui/scroll-area';
import { Rebate } from './Rebate';
import { ChatContext } from 'contexts/ChatContext';
import axios from 'axios';
import TouchpointSystem from 'components/touchpoint/TouchpointSystem';

export default function CustomerTemplate() {
  const { custcd } = useParams();
  const { customerProfilePage } = useContext(DataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Check if the current customer matches the `custcd` from params
  const isCorrectCustomer = customerProfilePage?.custcd === custcd;
  const customerName = isCorrectCustomer ? customerProfilePage?.custname : null;

  return (
    <div className="w-full border-b border-zinc-950/10 pb-4 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4">
        {/* Left Column */}
        <div className="w-full xl:w-1/2 xl:pr-4 space-y-4 pb-4">
          <KPIs />
          <div className="xl:hidden"> {/* Only show on mobile */}
            <div className="space-y-4">
              <CustomerProfile />
              <Insights />
              <Notes />
              <TouchpointSystem custcd={custcd} custname={customerName} isCompactView={true}/>
              <Rebate />
            </div>
          </div>
          <CustomerSalesTable />
          <MinimumSpendPotential />
          <Purchases />
        </div>

        {/* Right Column */}
        <div className="hidden xl:block w-full xl:w-1/2 space-y-4">
          <CustomerProfile />
          <Insights />
          <Notes />
          <TouchpointSystem custcd={custcd} custname={customerName} isCompactView={true}/>
          <Rebate />
        </div>
      </div>
    </div>
  );
}
