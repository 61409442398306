import React, { useState, useEffect, useRef, useContext } from 'react'
import { cn } from 'utils/utils'
import { AppContext } from 'contexts/AppContext';
import { CheckCircle2, XCircle, CircleArrowRight } from 'lucide-react'
import { SnoozeOutlined } from "@mui/icons-material";
import { DataContext } from 'contexts/DataContext';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";

export function InsightCard({ item }) {
  const { logInsightFeedback, completeInsight, rejectInsight } = useContext(DataContext);
  const [feedback, setFeedback] = useState("");
  const [isXPopoverOpen, setIsXPopoverOpen] = useState(false);
  const [isCheckPopoverOpen, setIsCheckPopoverOpen] = useState(false);

  
  const handleDismiss = (e) => {
    e.stopPropagation();
    rejectInsight(item.task_id);
    if (feedback.trim()) {
      logInsightFeedback(item.task_id, false, feedback);
    }
    setIsXPopoverOpen(false);
    setFeedback("");
  };

  const handleComplete = (e) => {
    e.stopPropagation();
    completeInsight(item.task_id);
    if (feedback.trim()) {
      logInsightFeedback(item.task_id, true, feedback);
    }
    setIsCheckPopoverOpen(false);
    setFeedback("");
    setLocalFeedback(true);
  };

  return (
    <div className={cn(
      'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent relative',
      item.selected && 'bg-muted'
    )}>
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2 flex-1 min-w-0">
            <div className="font-semibold truncate">
              {typeof item.cosailor_task[0] === 'object' ? JSON.stringify(item.cosailor_task[0]) : item.cosailor_task[0]}
            </div>
            {item.status === "accepted" && (
              <CheckCircle2 className="h-4 w-4 text-green-500 flex-shrink-0" />
            )}
            {item.status === "snoozed" && (
              <SnoozeOutlined className="flex-shrink-0" fontSize="small"/>
            )}
            
            {item.status !== "accepted" && (
              <TooltipProvider>
                <div className="flex items-center gap-1 flex-shrink-0">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Popover open={isCheckPopoverOpen} onOpenChange={setIsCheckPopoverOpen}>
                        <PopoverTrigger asChild>
                          <button
                            className="text-gray-400 hover:text-gray-700 transition-colors p-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsCheckPopoverOpen(true);
                            }}
                          >
                            <CheckCircle2
                              className="h-3.5 w-3.5"
                            />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-80" onClick={(e) => e.stopPropagation()}>
                          <div className="grid w-full gap-4">
                            <Label>How did it go?</Label>
                            <Textarea
                              placeholder="Your feedback helps us improve."
                              value={feedback}
                              onChange={(e) => setFeedback(e.target.value)}
                            />
                            <div className="flex justify-end gap-2">
                              <Button 
                                size="sm" 
                                variant="outline" 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsCheckPopoverOpen(false);
                                  setFeedback("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button 
                                size="sm"
                                onClick={handleComplete}
                              >
                                Complete
                              </Button>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </TooltipTrigger>
                    <TooltipContent>Mark as Complete</TooltipContent>
                  </Tooltip>
                  
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Popover open={isXPopoverOpen} onOpenChange={setIsXPopoverOpen}>
                        <PopoverTrigger asChild>
                          <button
                            className="text-gray-400 hover:text-gray-700 transition-colors p-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsXPopoverOpen(true);
                            }}
                          >
                            <XCircle
                              className="h-3.5 w-3.5"
                            />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-80" onClick={(e) => e.stopPropagation()}>
                          <div className="grid w-full gap-4">
                            <Label>Why isn't this insight useful?</Label>
                            <Textarea
                              placeholder="Your feedback helps us improve."
                              value={feedback}
                              onChange={(e) => setFeedback(e.target.value)}
                            />
                            <div className="flex justify-end gap-2">
                              <Button 
                                size="sm" 
                                variant="outline" 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsXPopoverOpen(false);
                                  setFeedback("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button 
                                size="sm"
                                onClick={handleDismiss}
                              >
                                Dismiss
                              </Button>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </TooltipTrigger>
                    <TooltipContent>Dismiss Insight</TooltipContent>
                  </Tooltip>
                </div>
              </TooltipProvider>
            )}
          </div>

          <div className={cn("flex items-center gap-3 flex-shrink-0", 
            item.selected ? "text-foreground" : "text-muted-foreground"
          )}>
            <span className="text-xs">{new Date(item.compute_time).toLocaleDateString()}</span>
            <button className="text-gray-400 hover:text-gray-700 transition-colors">
              <CircleArrowRight className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div className="text-xs font-medium">{item.subject}</div>
        <div className="line-clamp-2 text-xs text-muted-foreground">
          {typeof item.cosailor_task[1] === 'object' ? JSON.stringify(item.cosailor_task[1]) : item.cosailor_task[1]}
        </div>
      </div>
    </div>
  )
}