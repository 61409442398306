import React, { useState, useEffect } from 'react';
import {
  Card,
  Title,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Button,
  Badge,
  Select,
  SelectItem,
  Icon,
  Flex,
  TextInput,
  DatePicker,
} from '@tremor/react';

import {
  Calendar as CalendarIcon,
  Plus,
  Filter,
  Share2,
  Printer,
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  Grid,
  Search,
  X,
} from "lucide-react";

import { Button as ShadButton } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";

import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { HomeIcon } from "lucide-react";
import { Link } from "react-router-dom";
import CalendarTopHeader from './components/CalendarTopHeader';
import CalendarHeader from './components/CalendarHeader';
import CalendarGrid from './components/CalendarGrid';
import CalendarSidebar from './components/CalendarSidebar';
import ViewSelector from './components/ViewSelector';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const mockEvents = [
  {
    id: 1,
    title: 'Team Meeting',
    date: '2024-03-20',
    time: '10:00 AM',
    type: 'work',
    location: 'Conference Room A',
    description: 'Weekly team sync',
  },
  {
    id: 2,
    title: 'Client Call',
    date: '2024-03-20',
    time: '2:00 PM',
    type: 'meeting',
    location: 'Zoom',
    description: 'Project review with client',
  },
];

const NewEventModal = ({ isOpen, setIsOpen }) => {
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
      <Card className="w-[600px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <Title>New event</Title>
          <Button icon={X} variant="light" onClick={() => setIsOpen(false)} />
        </div>
        
        <div className="space-y-4">
          <div>
            <Text className="mb-2">Title</Text>
            <TextInput placeholder="Add a title" className="w-full" />
          </div>

          <div className="flex gap-4">
            <div className="flex-1">
              <Text className="mb-2">Start</Text>
              <DatePicker className="w-full" />
              <Select className="mt-2" defaultValue="5:00 PM">
                <SelectItem value="5:00 PM">5:00 PM</SelectItem>
                <SelectItem value="5:30 PM">5:30 PM</SelectItem>
                <SelectItem value="6:00 PM">6:00 PM</SelectItem>
              </Select>
            </div>
            <div className="flex-1">
              <Text className="mb-2">End</Text>
              <DatePicker className="w-full" />
              <Select className="mt-2" defaultValue="5:30 PM">
                <SelectItem value="5:30 PM">5:30 PM</SelectItem>
                <SelectItem value="6:00 PM">6:00 PM</SelectItem>
                <SelectItem value="6:30 PM">6:30 PM</SelectItem>
              </Select>
            </div>
          </div>

          <div>
            <Text className="mb-2">Location</Text>
            <TextInput placeholder="Add a location" className="w-full" />
          </div>

          <div>
            <Text className="mb-2">Description</Text>
            <TextInput placeholder="Add a description" className="w-full" />
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary">
              Save
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

const MiniCalendar = ({ currentDate, onDateSelect }) => {
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const days = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    days.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return (
    <Card className="w-full max-w-[250px]">
      <div className="grid grid-cols-7 gap-1 text-xs">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-medium p-1">
            {day.charAt(0)}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`text-center p-1 cursor-pointer hover:bg-gray-100 ${
              day === currentDate.getDate() ? 'bg-blue-100' : ''
            }`}
            onClick={() => day && onDateSelect(day)}
          >
            <Text>{day}</Text>
          </div>
        ))}
      </div>
    </Card>
  );
};

const CalendarView = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month');
  const [calendars, setCalendars] = useState([
    { id: 1, name: 'My Calendar', enabled: true },
    { id: 2, name: 'Team Calendar', enabled: true },
    { id: 3, name: 'Holidays', enabled: true },
  ]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handleToggleCalendar = (calendarId) => {
    setCalendars(calendars.map(cal => 
      cal.id === calendarId ? { ...cal, enabled: !cal.enabled } : cal
    ));
  };

  return (
    <div className="flex flex-col h-[calc(100vh-100px)]">
      <div className="px-4 sm:px-6 py-4 border-b">
        <CalendarTopHeader />
      </div>
      <div className="flex flex-col sm:flex-row h-full">
        {/* Mobile Sidebar Toggle */}
        <Button
          variant="light"
          icon={isSidebarOpen ? ChevronLeft : ChevronRight}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="sm:hidden my-2"
        >
          {isSidebarOpen ? 'Hide Calendar List' : 'Show Calendar List'}
        </Button>

        {/* Sidebar */}
        <div className={`
          ${isSidebarOpen ? 'block' : 'hidden'} 
          sm:block
          w-full sm:w-64 border-b sm:border-b-0 sm:border-r
          ${isSidebarOpen ? 'h-auto' : 'h-0'} sm:h-full
        `}>
          <CalendarSidebar
            calendars={calendars}
            onToggleCalendar={handleToggleCalendar}
          />
        </div>

        {/* Main Content */}
        <div className="flex-1">
          <div className="px-4 sm:px-6 py-4 border-b overflow-x-auto">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-0">
              <CalendarHeader
                currentDate={currentDate}
                onPrevMonth={handlePrevMonth}
                onNextMonth={handleNextMonth}
              />
              <ViewSelector currentView={view} onViewChange={setView} />
            </div>
          </div>
          <div className="p-4 overflow-x-auto">
            <CalendarGrid currentDate={currentDate} events={mockEvents} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Calendar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <CalendarView />
    </div>
  );
} 