"use client"

import React, { useContext, useState, useEffect, useCallback } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { AppContext } from "contexts/AppContext";
import { ChatContext } from "contexts/ChatContext";
import { cn } from "utils/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "components/ui/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Text } from "components/catalyst/text";
import { Button } from "components/ui/button";
import { ChevronsUpDown } from "lucide-react";
import { usePersistedAccount } from "hooks/usePersistedAccount";
import { useNavigate, useLocation } from 'react-router-dom';

export function AccountSelect({ isCollapsed = false }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAdmin, accounts, setSalesAgentCD, accessInfo } = useContext(AppContext);
  const { handleClearState } = useContext(ChatContext);
  const [persistedAccount, setPersistedAccount] = usePersistedAccount("");
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (accessInfo?.srs_role === "TM") {
    return null;
  }

  const handleChange = (value) => {
    setPersistedAccount(value.salesagentcd);
    setSalesAgentCD(value.salesagentcd); 
    if (location.pathname.startsWith('/customer/')) {
      navigate('/'); 
    }
    window.scrollTo(0, 0);
    handleClearState()
  };

  useEffect(() => {
    if (persistedAccount) {
      setSalesAgentCD(persistedAccount);
    }
  }, [persistedAccount, setSalesAgentCD]);

  // Handle clicks outside the drawer for mobile
  const handleOutsideClick = useCallback((event) => {
    if (open && event.target.closest(".drawer-content") === null) {
      setOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (!isDesktop && open) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open, handleOutsideClick, isDesktop]);

  // Return null for TM users after all hooks are initialized
  if (accessInfo?.srs_role === "TM") {
    return null;
  }

  // Return placeholder for loading state after all hooks are initialized
  if (!accounts?.length) {
    return <div className="mt-4"></div>;
  }

  const AccountList = ({ setOpen }) => (
    <Command>
      <CommandInput placeholder="Search accounts..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {accounts.map((account) => (
            <CommandItem
              key={account.salesagentcd}
              value={account}
              onSelect={() => {
                handleChange(account);
                setOpen(false);
              }}
            >
              <div className="flex items-center gap-3">
                {account.first_name} {account.last_name} ({account.salesagentcd})
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  const selectedAccount = accounts.find((account) => account.salesagentcd === persistedAccount) || accounts[0];

  return isDesktop ? (
    <div className="mt-4">
      <span className="block truncate pb-2 text-sm/5 font-medium text-zinc-950 dark:text-white">
        <Text>Select Account</Text>
      </span>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full flex items-center justify-between gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
              isCollapsed &&
                "flex h-9 w-full shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden"
            )}
            aria-label="Select account"
          >
            <span className="flex items-center gap-2">
              {selectedAccount?.icon}
              <span className={cn("ml-2", isCollapsed && "hidden")}>
                {selectedAccount
                  ? `${selectedAccount.first_name} ${selectedAccount.last_name} (${selectedAccount.salesagentcd})`
                  : "Select an account"}
              </span>
            </span>
            <ChevronsUpDown className="h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">
          <AccountList setOpen={setOpen} />
        </PopoverContent>
      </Popover>
    </div>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="w-full justify-between mt-4">
          <span className="flex items-center gap-2">
            {selectedAccount
              ? `${selectedAccount.first_name} ${selectedAccount.last_name} (${selectedAccount.salesagentcd})`
              : "Select an account"}
          </span>
          <ChevronsUpDown className="h-4 w-4 opacity-50" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="w-full drawer-content">
        <div className="mt-4 border-t">
          <AccountList setOpen={setOpen} />
        </div>
      </DrawerContent>
    </Drawer>
  );
}