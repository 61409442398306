export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM5NDAyNzU4LCJleHAiOjE3Mzk0MDYzNTgsImp0aSI6IklELmpuZ0FUZDB1SUlUVzZaempxZlhXcmF3eThSeE9JWDlNSnJabm1JX25yWDgiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJxRXR0bEZNcThxejJ1eVE3ODVUWmdkQXUyN1RwTEZZSlE1RjhPb3hkVkNMallLeWRzQUhEM2djUWRPZ3FPSDQ5IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTczOTM3NDE5MCwiYXRfaGFzaCI6IkluZkZYVi1uOWNYVFk2TUNyandEVFEifQ.HC42a_B4EvMKkiv9UgReimgeSApxufVy7a2I2i2sVEXkJGt3awDoqdSyAh6mUSLq7kRBzqa9Q4hNl4h32GKGqa-Rk7l51ormCNrBrnK9D5ml2QarFt89uDmrsaoDsZW1PeuenyEYbcZoNbrEEIknLFoMZyexNj1LYcDEujBeMfKl486OP_WNIiMlOk_xPjhTYTW0epLy3jdEa0fyAqILmjA_5fkQdGU4tpO2YsySt3x-CtdmCTueNNNwKgru6UMYlAWv7FjLQvYSbh1vi3tQE8IjZ5fszHNFdGFcQUf3UW8RJs9bPvAZXRiTLNN7esGw91t6sQwblQo5sAtHSMi3EA"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
