import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { toast } from "sonner";
import { AppContext } from "contexts/AppContext";
import { ContactsContext } from "contexts/ContactsContext";
import { Button } from "components/ui/button";
import { Bold, Italic, Underline } from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";

const ActionCardsEmail = forwardRef(({ outreachPayload, card }, ref) => {
  const messageContentRef = useRef("");
  const { sendEmail, oktaId } = useContext(AppContext);
  const { getPrimaryInfo } = useContext(ContactsContext);

  const parseOutreachPayload = (payload) => {
    const [subjectLine, ...messageParts] = payload.trim().split("\n\n");
    const subject = subjectLine.replace("Subject: ", "").trim();
    const message = messageParts.join("\n\n").trim();
    return { subject, message };
  };

  const { subject, message } = parseOutreachPayload(card.outreach_payload);
  const formattedMessage = message.replace(/\n/g, "<br />");

  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [emailSubject, setEmailSubject] = useState(subject);
  const [emailContent, setEmailContent] = useState(formattedMessage);
  const [attachments, setAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setEmailSubject(subject);
    setEmailContent(formattedMessage);

    const fetchPrimaryContact = async () => {
      if (card.custcd && card.salesagentcd && oktaId) {
        const primaryInfoData = {
          custcd: card.custcd,
          salesagentcd: card.salesagentcd,
          oktaid: oktaId,
        };

        const primaryContact = await getPrimaryInfo(primaryInfoData);
        if (primaryContact) {
          const primaryContactInfo = JSON.parse(
            primaryContact.primary_contact_info
          );
          if (primaryContactInfo.contact_email) {
            setTo(primaryContactInfo.contact_email);
          }
        }
      }
    };

    fetchPrimaryContact();
  }, [outreachPayload, card.custcd, card.salesagentcd, oktaId]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const parseEmails = (input) => {
    const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
    return Array.from(input.matchAll(regex), (match) => match[1]);
  };

  const handleSubmit = async () => {
    const toEmails = parseEmails(to);
    const ccEmails = parseEmails(cc);
    const bccEmails = parseEmails(bcc);

    messageContentRef.current = document.getElementById("message").innerHTML;

    const missingFields = [];
    if (!to) missingFields.push("email address");
    if (!emailSubject) missingFields.push("subject");
    if (!emailContent) missingFields.push("email content");

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(", ")}`);
      return;
    }
    setErrorMessage("");

    const formattedAttachments = attachments.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          const binaryString = e.target.result;
          const base64String = btoa(binaryString);
          resolve({
            file_content: base64String,
            file_name: file.name,
            content_type: file.type,
          });
        };
        reader.readAsBinaryString(file);
      });
    });

    const formattedAttachmentsArray = await Promise.all(formattedAttachments);

    try {
      await sendEmail(
        toEmails,
        ccEmails,
        bccEmails,
        emailSubject,
        messageContentRef.current,
        formattedAttachmentsArray
      );
      toast.success("Email successfully sent!");
      return true;
    } catch (error) {
      console.error("Error sending email:", error);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    getToFieldValue: () => to,
    getMessageContent: () => messageContentRef.current,
    getSubject: () => emailSubject,
    handleSubmit,
  }));

  return (
    <div className="grid gap-4">
      <div className="flex items-center gap-2">
        <label htmlFor="sendTo" className="ml-9 block text-sm font-medium">
          To:
        </label>
        <input
          id="sendTo"
          type="email"
          placeholder="Enter recipient's email"
          value={to}
          onChange={(e) => setTo(e.target.value)}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm"
        />
      </div>

      <div className="flex items-center gap-2">
        <label htmlFor="subjectLine" className="block text-sm font-medium">
          Subject:
        </label>
        <input
          id="subjectLine"
          type="text"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          placeholder="Enter subject"
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm"
        />
      </div>

      <div>
        <ToggleGroup size="sm" type="multiple" aria-label="Text formatting">
          <ToggleGroupItem
            value="bold"
            aria-label="Toggle bold"
            onClick={() => document.execCommand("bold")}
          >
            <Bold className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="italic"
            aria-label="Toggle italic"
            onClick={() => document.execCommand("italic")}
          >
            <Italic className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="underline"
            aria-label="Toggle underline"
            onClick={() => document.execCommand("underline")}
          >
            <Underline className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>

        <div
          id="message"
          contentEditable
          dangerouslySetInnerHTML={{
            __html: formattedMessage,
          }}
          onChange={(e) => setEmailContent(e.target.innerHTML)}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm max-h-[200px] overflow-y-auto"
        />
      </div>

      <div className="flex gap-2">
        <label htmlFor="attachments" className="sm:text-right sm:mt-2 text-md">
          Attachments:
        </label>
        <div className="col-span-1 sm:col-span-3">
          <div className="flex items-center gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => document.getElementById("attachments").click()}
            >
              Choose Files
            </Button>
            <input
              id="attachments"
              type="file"
              multiple
              onChange={handleFileChange}
              className="hidden"
            />
            {attachments.length > 0 && (
              <span>{attachments.length} file(s) selected</span>
            )}
          </div>
          {attachments.length > 0 && (
            <ul className="list-disc pl-5 space-y-1 mt-2">
              {attachments.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between text-sm"
                >
                  <span className="truncate mr-2">{file.name}</span>
                  <button type="button" onClick={() => removeAttachment(index)}>
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
});

export default ActionCardsEmail;
