import React from 'react';
import { Text, Badge } from '@tremor/react';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const CalendarGrid = ({ currentDate, events }) => {
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startingDay = firstDayOfMonth.getDay();
  const totalDays = lastDayOfMonth.getDate();

  const days = [];
  for (let i = 0; i < startingDay; i++) {
    days.push(null);
  }
  for (let i = 1; i <= totalDays; i++) {
    days.push(i);
  }

  return (
    <div className="min-w-[640px]">
      <div className="grid grid-cols-7 gap-1">
        {daysOfWeek.map((day) => (
          <div key={day} className="p-2 text-center font-semibold bg-gray-100">
            <Text className="hidden sm:block">{day}</Text>
            <Text className="sm:hidden">{day.charAt(0)}</Text>
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`p-2 min-h-[80px] sm:min-h-[120px] border ${
              day ? 'hover:bg-gray-50 cursor-pointer' : 'bg-gray-50'
            }`}
          >
            {day && (
              <>
                <Text className="text-right text-sm sm:text-base">{day}</Text>
                <div className="mt-1">
                  {events
                    .filter(
                      (event) =>
                        new Date(event.date).getDate() === day &&
                        new Date(event.date).getMonth() === currentDate.getMonth()
                    )
                    .map((event) => (
                      <Badge
                        key={event.id}
                        color={event.type === 'work' ? 'blue' : 'red'}
                        className="mb-1 w-full text-xs sm:text-sm"
                      >
                        <div className="flex flex-col">
                          <Text className="font-medium">{event.time}</Text>
                          <Text className="hidden sm:block">{event.title}</Text>
                          <Text className="sm:hidden">{event.title.substring(0, 10)}...</Text>
                          <Text className="opacity-75 hidden sm:block">{event.location}</Text>
                        </div>
                      </Badge>
                    ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarGrid; 