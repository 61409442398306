import React, { useState, useContext } from "react";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
} from "components/ui/card";
import {
  Lightbulb,
  AlarmCheckIcon,
  CalendarIcon,
  DollarSignIcon,
  Award,
  Trophy,
} from "lucide-react";
import { DataContext } from "contexts/DataContext";
import { RemindersContext } from "contexts/RemindersContext";
import { formatValue } from "utils/formatValue";

export function NotifyCards() {
  const {
    sales30,
    salesYear,
    pyMTDSales,
    pyYTDSales,
    regionalRank,
    nationalRank,
    aggregateInsights,
  } = useContext(DataContext);
  const { reminders } = useContext(RemindersContext);

  const formatValue = (value) => {
    if (!value) return "\u00A0";
    if (value >= 1000 && value < 1000000)
      return `$${(value / 1000).toFixed(1)}K`;
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    return `$${Math.floor(value).toLocaleString()}`;
  };

  const formatRank = (value) => {
    return value || "\u00A0";
  };

  const getYoYDifference = (current, previous) => {
    if (!current || !previous) return null;
    const diff = current - previous;
    return {
      value: formatValue(Math.abs(diff)), // Always show absolute value
      color: diff >= 0 ? "text-green-600" : "text-red-600",
      sign: diff > 0 ? "+" : diff < 0 ? "-" : "", // Only show "+" or "-"
    };
  };

  const mtdDiff = getYoYDifference(sales30, pyMTDSales);
  const ytdDiff = getYoYDifference(salesYear, pyYTDSales);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#FFF0C4] rounded-full hidden sm:flex">
            <Award className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatRank(regionalRank)}</CardTitle>
            <CardDescription>Regional Rank</CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#FCCECA] rounded-full hidden sm:flex">
            <Trophy className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatRank(nationalRank)}</CardTitle>
            <CardDescription>National Rank</CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1 relative">
        {" "}
        {/* Added relative for absolute positioning */}
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#F1EAFA] rounded-full hidden sm:flex">
            <CalendarIcon className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatValue(sales30)}</CardTitle>
            <CardDescription>MTD Sales</CardDescription>
            {mtdDiff && (
              <div className="absolute bottom-2 left-0 right-0 flex justify-center text-xs">
                <span className={`${mtdDiff.color}`}>
                  YoY: {mtdDiff.sign}
                  {mtdDiff.value}
                </span>
              </div>
            )}
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1 relative">
        {" "}
        {/* Ensure parent is relative */}
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#CCF8DA] rounded-full hidden sm:flex">
            <DollarSignIcon className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatValue(salesYear)}</CardTitle>
            <CardDescription>YTD Sales</CardDescription>
            {ytdDiff && (
              <div className="absolute bottom-2 left-0 right-0 flex justify-center text-xs">
                <span className={`${ytdDiff.color}`}>
                  YoY: {ytdDiff.sign}
                  {ytdDiff.value}
                </span>
              </div>
            )}
          </div>
        </CardHeader>
      </Card>
    </div>
  );
}
