import React, { useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { DataContext } from "contexts/DataContext";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO } from "date-fns";

export function SalesAnalysisChart() {
  const { salesPerformanceData } = useContext(DataContext);

  const { mtd_data = [], ytd_data = [] } = salesPerformanceData || {};

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "$0";
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const CustomTooltip = ({ active, payload, label, isMTD }) => {
    if (!active || !payload || !payload.length) return null;

    const dateFormat = isMTD ? "MMM d" : "MMM dd, yyyy";
    return (
      <div className="bg-white p-4 border rounded shadow">
        <p className="font-bold">{format(parseISO(label), dateFormat)}</p>
        {payload.map((entry) => (
          <p 
            key={entry.dataKey} 
            style={{ color: entry.color, margin: "4px 0" }}
          >
            {entry.name}: {formatCurrency(entry.value)}
          </p>
        ))}
      </div>
    );
  };

  const renderChart = (data, currentYearKey, previousYearKey, isMTD = false) => {
    if (!data || data.length === 0) {
      return (
        <div className="flex items-center justify-center h-96">
          <p className="text-gray-500">No data available</p>
        </div>
      );
    }

    const dateFormat = isMTD ? "d" : "MMM dd";
    const currentLabel = isMTD ? "CY MTD" : "CY YTD";
    const previousLabel = isMTD ? "PY MTD" : "PY YTD";

    return (
      <div className="min-w-[800px] h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="perioddt"
              tickFormatter={(date) => format(parseISO(date), dateFormat)}
              tick={{ fontSize: 12 }}
            />
            <YAxis 
              tickFormatter={formatCurrency}
              tick={{ fontSize: 12 }}
              scale="linear"
              domain={[0, 'auto']}
              allowDecimals={false}
              tickCount={6}
            />
            <Tooltip content={(props) => <CustomTooltip {...props} isMTD={isMTD} />} />
            <Legend />
            <Line
              type="monotone"
              dataKey={currentYearKey}
              name={currentLabel}
              stroke="#1d4ed8"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey={previousYearKey}
              name={previousLabel}
              stroke="#f97316"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Sales Performance</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="mtd" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="mtd">Month to Date</TabsTrigger>
            <TabsTrigger value="ytd">Year to Date</TabsTrigger>
          </TabsList>

          <TabsContent value="mtd" className="mt-4">
            <div className="overflow-x-auto">
              {renderChart(mtd_data, "mtd_sales_cy", "mtd_sales_py", true)}
            </div>
          </TabsContent>

          <TabsContent value="ytd" className="mt-4">
            <div className="overflow-x-auto">
              {renderChart(ytd_data, "ytd_sales_cy", "ytd_sales_py", false)}
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}