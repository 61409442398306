import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { SidebarItem } from 'components/catalyst/sidebar';
import { ChevronUpIcon, ArrowRightStartOnRectangleIcon, LightBulbIcon, UserIcon } from '@heroicons/react/16/solid';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown';
import { AppContext } from 'contexts/AppContext';
import { ModeToggle } from './ModeToggle';
import srs from 'img/srs.png'
import instalily from 'img/logo_mini.png'

export function UserDropdown({ closeSidebar }) {
  const { user, logEvent } = useContext(AppContext);
  
  const handleSignOut = async () => {
    console.log("SIGNING OUT");
    logEvent('Cosailor', 'Sign Out',null )
  };

  return (
    <Dropdown>
      <DropdownButton as={SidebarItem}>
        <span className="flex flex min-w-0 w-full items-center gap-3">
          <Avatar>
            <AvatarImage src={instalily} className="w-8 h-8 p-1 m-auto bg-white" />
            <AvatarFallback>SRS</AvatarFallback>
          </Avatar>
          <span className="min-w-0 flex-1">
            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
              {user?.firstName} {user?.lastName}
            </span>
            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
              {user?.userId}@mmhfgb.com
            </span>
          </span>
          <ChevronUpIcon className="h-5 w-5" /> {/* Explicitly size the Chevron icon */}
        </span>
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="top start">
        <DropdownItem>
          <Link to="/profile" onClick={() => { console.log('Navigating to profile'); closeSidebar(); }} className="flex items-center gap-2 w-full">
            <UserIcon className="h-5 w-5" />
            <DropdownLabel>My profile</DropdownLabel>
          </Link>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <Link to="/feedback" onClick={() => { console.log('Navigating to feedback'); closeSidebar(); }} className="flex items-center gap-2 w-full">
            <LightBulbIcon className="h-5 w-5" />
            <DropdownLabel>Share feedback</DropdownLabel>
          </Link>
        </DropdownItem>
        <DropdownItem className="hidden md:flex">
          <ModeToggle />
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <button onClick={() => { console.log('Navigating to logout'); closeSidebar(); handleSignOut();  }} className="flex items-center gap-2 w-full">
            <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
            <DropdownLabel>Sign out</DropdownLabel>
          </button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
