import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { 
  Send, 
  MessageSquare, 
  MoreHorizontal,
  ChevronLeft,
  ChevronRight,
  Clock,
  TrendingUp,
  ThumbsUp,
  Filter
} from "lucide-react"
import { 
  Text,
  Title,
  Grid,
  Col,
  Button,
  Badge,
  TextInput,
  Metric,
  Flex,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Select,
  SelectItem,
  List,
  ListItem
} from "@tremor/react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  Card,
  CardHeader,
  CardContent,
} from "components/ui/card"
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid"

const topics = [
  "Sales", "Product", "Customer Service", "Technical", "Market Trends", "Supply Chain"
]

// Add color mapping for topics
const topicColors = {
  'Sales': 'emerald',
  'Product': 'blue',
  'Customer Service': 'amber',
  'Technical': 'violet',
  'Market Trends': 'rose',
  'Supply Chain': 'indigo'
}

const POSTS_PER_PAGE = 5

export default function WaterCoolerPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [newPost, setNewPost] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortByRecency, setSortByRecency] = useState('most')
  const [sortByActivity, setSortByActivity] = useState('none')
  const [sortByVotes, setSortByVotes] = useState('none')
  const [posts, setPosts] = useState([
    {
      id: 1,
      content: "What's your go-to strategy for upselling premium roofing materials to hesitant customers?",
      author: "User 54321",
      time: "2h ago",
      votes: 42,
      comments: 8,
      topics: ["Sales", "Product"],
      timestamp: Date.now() - 2 * 60 * 60 * 1000,
    },
    {
      id: 2,
      content: "Just heard about a new eco-friendly shingle that's supposed to last 50 years. Anyone have experience with it?",
      author: "User 67890",
      time: "5h ago",
      votes: 38,
      comments: 12,
      topics: ["Product", "Technical", "Market Trends", "Supply Chain"],
      timestamp: Date.now() - 5 * 60 * 60 * 1000,
    },
    {
      id: 3,
      content: "Supply chain delays are killing us. How are you managing customer expectations during these times?",
      author: "User 13579",
      time: "1d ago",
      votes: 56,
      comments: 23,
      topics: ["Customer Service", "Supply Chain"],
      timestamp: Date.now() - 24 * 60 * 60 * 1000,
    },
    {
      id: 4,
      content: "Looking for recommendations on the best project management software for roofing companies. Any suggestions?",
      author: "User 24680",
      time: "3h ago",
      votes: 15,
      comments: 7,
      topics: ["Technical"],
      timestamp: Date.now() - 3 * 60 * 60 * 1000,
    },
    {
      id: 5,
      content: "Has anyone implemented a successful referral program? What incentives work best?",
      author: "User 11223",
      time: "6h ago",
      votes: 28,
      comments: 14,
      topics: ["Sales", "Customer Service"],
      timestamp: Date.now() - 6 * 60 * 60 * 1000,
    },
    {
      id: 6,
      content: "Discussing the impact of recent tariffs on imported roofing materials. How are you adjusting your pricing?",
      author: "User 33445",
      time: "1d ago",
      votes: 37,
      comments: 19,
      topics: ["Market Trends", "Supply Chain"],
      timestamp: Date.now() - 24 * 60 * 60 * 1000,
    },
    {
      id: 7,
      content: "New regulations on energy-efficient roofing in our state. Anyone else dealing with this?",
      author: "User 55667",
      time: "4h ago",
      votes: 31,
      comments: 16,
      topics: ["Technical", "Market Trends"],
      timestamp: Date.now() - 4 * 60 * 60 * 1000,
    },
  ])

  const [votedPosts, setVotedPosts] = useState({})
  const [flashVotes, setFlashVotes] = useState({})

  const handleNewPostSubmit = (e) => {
    e.preventDefault();
    if (newPost.trim() === '') return;

    const newPostObject = {
      id: posts.length + 1,
      content: newPost,
      author: "Current User", // Replace with actual user data
      time: "Just now",
      votes: 0,
      comments: 0,
      topics: ["General"], // Default topic or allow user to select
      timestamp: Date.now(),
    };

    setPosts([newPostObject, ...posts]);
    setNewPost(''); // Clear the input field
  };

  const handleResetFilters = () => {
    setSelectedTopic('');
    setSortByRecency('most');
    setSortByActivity('none');
    setSortByVotes('none');
    setCurrentPage(1);
  };

  const filteredPosts = selectedTopic
    ? posts.filter(post => post.topics.includes(selectedTopic))
    : posts

  const sortedPosts = [...filteredPosts].sort((a, b) => {
    if (sortByRecency !== 'none') {
      return sortByRecency === 'most' ? b.timestamp - a.timestamp : a.timestamp - b.timestamp
    }
    if (sortByActivity !== 'none') {
      return sortByActivity === 'most' ? b.comments - a.comments : a.comments - b.comments
    }
    if (sortByVotes !== 'none') {
      return sortByVotes === 'most' ? b.votes - a.votes : a.votes - b.votes
    }
    return 0
  })

  const totalPages = Math.ceil(sortedPosts.length / POSTS_PER_PAGE)
  const paginatedPosts = sortedPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    window.scrollTo(0, 0)
  }

  const handleSortChange = (type, value) => {
    if (type === 'recency') {
      setSortByRecency(value)
      setSortByActivity('none')
      setSortByVotes('none')
    } else if (type === 'activity') {
      setSortByRecency('none')
      setSortByActivity(value)
      setSortByVotes('none')
    } else if (type === 'votes') {
      setSortByRecency('none')
      setSortByActivity('none')
      setSortByVotes(value)
    }
    setCurrentPage(1)
  }

  const handleVote = (postId, direction) => {
    setPosts(posts.map(post => {
      if (post.id === postId) {
        if (votedPosts[postId] === direction) {
          setVotedPosts(prev => ({ ...prev, [postId]: null }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? -1 : 1)
          }
        }
        else if (votedPosts[postId]) {
          setVotedPosts(prev => ({ ...prev, [postId]: direction }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? 2 : -2)
          }
        }
        else {
          setVotedPosts(prev => ({ ...prev, [postId]: direction }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? 1 : -1)
          }
        }
      }
      return post
    }))

    setFlashVotes(prev => ({ ...prev, [postId]: direction }))
    
    setTimeout(() => {
      setFlashVotes(prev => ({ ...prev, [postId]: null }))
    }, 1000)
  }

  // Add metrics calculations
  const totalPosts = posts.length;
  const totalComments = posts.reduce((sum, post) => sum + post.comments, 0);
  const totalVotes = posts.reduce((sum, post) => sum + post.votes, 0);
  const avgCommentsPerPost = (totalComments / totalPosts).toFixed(1);

  return (
    <div className="p-2 sm:p-4 md:p-6">
      <Grid numItems={1} numItemsSm={1} numItemsMd={12} className="gap-6">
        {/* Left Sidebar - Full width on mobile, side column on desktop */}
        <Col numColSpan={1} numColSpanMd={3} className="space-y-6">
          {/* Profile Card */}
          <Card className="relative">
            <CardContent className="p-4">
              <Flex justifyContent="between" alignItems="center" className="mb-3">
                <Title>Water Cooler</Title>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="light" icon={MoreHorizontal} size="sm" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Menu</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                      <Link to="/profile">Profile</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Link to="/wc-saved">Saved Posts</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/wc-settings">Settings</Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </Flex>

              <div className="space-y-1.5">
                <Flex alignItems="center" justifyContent="between" className="border-b pb-1">
                  <Text className="text-gray-500 text-sm">Posts</Text>
                  <Text className="font-medium text-gray-700">{totalPosts}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between" className="border-b pb-1">
                  <Text className="text-gray-500 text-sm">Comments</Text>
                  <Text className="font-medium text-gray-700">{totalComments}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between" className="border-b pb-1">
                  <Text className="text-gray-500 text-sm">Votes</Text>
                  <Text className="font-medium text-gray-700">{totalVotes}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between">
                  <Text className="text-gray-500 text-sm">Avg Comments</Text>
                  <Text className="font-medium text-gray-700">{avgCommentsPerPost}</Text>
                </Flex>
              </div>
            </CardContent>
          </Card>

          {/* Topics Filter */}
          <Card className="relative">
            <CardContent className="p-4">
              <Flex alignItems="center" className="gap-2 mb-4">
                <Filter className="h-5 w-5 text-gray-500" />
                <Title>Topics</Title>
              </Flex>
              <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
                <Button
                  variant={selectedTopic === '' ? 'primary' : 'secondary'}
                  className="justify-start text-sm md:text-base"
                  onClick={() => setSelectedTopic('')}
                >
                  All Topics
                </Button>
                {topics.map(topic => (
                  <Button
                    key={topic}
                    variant={selectedTopic === topic ? 'primary' : 'secondary'}
                    className="justify-start text-sm md:text-base"
                    onClick={() => setSelectedTopic(topic)}
                    color={topicColors[topic]}
                  >
                    {topic}
                  </Button>
                ))}
              </div>
            </CardContent>
          </Card>
        </Col>

        {/* Main Content - Full width on mobile, 9 columns on desktop */}
        <Col numColSpan={1} numColSpanMd={9}>
          {/* New Post Input */}
          <Card className="relative mb-4 md:mb-6">
            <CardContent className="p-4">
              <form onSubmit={handleNewPostSubmit}>
                <Flex className="gap-4">
                  <TextInput
                    placeholder="Share an insight or ask a question..."
                    value={newPost}
                    onChange={(e) => setNewPost(e.target.value)}
                    className="flex-1"
                  />
                  <Button type="submit" icon={Send} className="shrink-0">
                    Share Post
                  </Button>
                </Flex>
              </form>
            </CardContent>
          </Card>

          {/* Sort Tabs - Scrollable on mobile */}
          <div className="overflow-x-auto mb-2">
            <TabGroup>
              <TabList variant="solid" className="w-full min-w-max p-2">
                <Tab 
                  icon={Clock}
                  onClick={() => handleSortChange('recency', 'most')}
                >
                  Recent
                </Tab>
                <Tab 
                  icon={TrendingUp}
                  onClick={() => handleSortChange('activity', 'most')}
                >
                  Active
                </Tab>
                <Tab 
                  icon={ThumbsUp}
                  onClick={() => handleSortChange('votes', 'most')}
                >
                  Top
                </Tab>
              </TabList>
            </TabGroup>
          </div>

          {/* Posts List */}
          <div className="space-y-4">
            {paginatedPosts.map((post) => (
              <div key={post.id}>
                <Card className="relative hover:bg-gray-50 transition-colors">
                  <CardContent className="p-4">
                    <Grid numItems={12} className="gap-4 md:gap-6">
                      {/* Vote Column - Horizontal on mobile, vertical on desktop */}
                      <Col numColSpan={12} numColSpanMd={1}>
                        <Flex 
                          direction={{ initial: "row", md: "col" }}
                          alignItems="center" 
                          justifyContent={{ initial: "start", md: "center" }}
                          className="mb-2 md:mb-0 pt-1"
                        >
                          <Button
                            variant="light"
                            size="xs"
                            icon={ArrowUpIcon}
                            color={votedPosts[post.id] === 'up' ? 'green' : 'gray'}
                            className={`transition-transform ${flashVotes[post.id] === 'up' ? 'scale-125' : ''}`}
                            onClick={() => handleVote(post.id, 'up')}
                          />
                          <Text 
                            className="font-bold min-w-[2rem] text-center"
                            color={
                              votedPosts[post.id] === 'up' 
                                ? 'green' 
                                : votedPosts[post.id] === 'down'
                                  ? 'red'
                                  : 'gray'
                            }
                          >
                            {post.votes}
                          </Text>
                          <Button
                            variant="light"
                            size="xs"
                            icon={ArrowDownIcon}
                            color={votedPosts[post.id] === 'down' ? 'red' : 'gray'}
                            className={`transition-transform ${flashVotes[post.id] === 'down' ? 'scale-125' : ''}`}
                            onClick={() => handleVote(post.id, 'down')}
                          />
                        </Flex>
                      </Col>

                      {/* Content Column */}
                      <Col numColSpan={12} numColSpanMd={11}>
                        <Link 
                          to={post.id === 1 ? "/wc-example" : "#"} 
                          className="block group"
                        >
                          <Title className="text-base md:text-lg mb-2 group-hover:text-blue-600 transition-colors">
                            {post.content}
                          </Title>
                        </Link>

                        <div className="overflow-x-auto">
                          <Flex className="gap-1 mb-3 min-w-max justify-start">
                            {post.topics.map(topic => (
                              <Badge 
                                key={topic} 
                                color={topicColors[topic]}
                                size="sm"
                                className="cursor-pointer hover:opacity-80 transition-colors whitespace-nowrap"
                                onClick={() => setSelectedTopic(topic)}
                              >
                                {topic}
                              </Badge>
                            ))}
                          </Flex>
                        </div>

                        <Flex 
                          justifyContent="between" 
                          alignItems="center"
                          className="flex-col md:flex-row gap-2 md:gap-0"
                        >
                          <Flex alignItems="center" className="gap-2">
                            <Text color="gray" className="text-sm">
                              {post.author}
                            </Text>
                            <Text color="gray">·</Text>
                            <Text color="gray" className="text-sm mr-6">
                              {post.time}
                            </Text>
                          </Flex>
                          <Button
                            variant="light"
                            size="xs"
                            icon={MessageSquare}
                            className="gap-2 w-full md:w-auto"
                          >
                            {post.comments} Comments
                          </Button>
                        </Flex>
                      </Col>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>

          {/* Pagination - Scrollable on mobile */}
          {totalPages > 1 && (
            <Card className="relative mt-4 md:mt-6 overflow-x-auto">
              <CardContent className="p-4">
                <Flex justifyContent="center" className="gap-2 min-w-max">
                  <Button
                    variant="secondary"
                    icon={ChevronLeft}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    size="sm"
                    className="md:size-md"
                  >
                    Previous
                  </Button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <Button
                      key={page}
                      variant={currentPage === page ? "primary" : "secondary"}
                      onClick={() => handlePageChange(page)}
                      size="sm"
                      className="md:size-md"
                    >
                      {page}
                    </Button>
                  ))}
                  <Button
                    variant="secondary"
                    icon={ChevronRight}
                    iconPosition="right"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    size="sm"
                    className="md:size-md"
                  >
                    Next
                  </Button>
                </Flex>
              </CardContent>
            </Card>
          )}
        </Col>
      </Grid>
    </div>
  )
}
