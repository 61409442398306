import React, { useContext, useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card'
import { InsightsList } from './companyinsights-component/insights-list';
import { DataContext } from 'contexts/DataContext';
import { Badge } from 'components/catalyst/badge';

const colorMapBackground = {
  'Order Inactivity': 'blue',
  'Net New Customer': 'amber',
  'New Customer Promo': 'cyan',
  'First invoice anniversary': 'purple',
  'First Invoice Anniversary': 'purple',
  'Open AR Payments': 'pink',
  'YoY Sales Drop': 'red',
  'Reduced Invoice Activity': 'orange',
  'Customer DRP dropped': 'rose',
  'Customer DRP Dropped': 'rose',
  'Eligible for new customer promo': 'sky',
  'Eligible For New Customer Promo': 'sky',
  'Customer DRP below expected': 'yellow',
  'Customer DRP Below Expected': 'yellow',
  'First SO Invoiced': 'teal',
  'First Digital SO Invoiced': 'indigo',
  'Limit Increased': 'green',
  'Residential': 'stone'
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || 'gray'; // Fallback to gray if not found
};

export function Insights() {
  const { customerInsights, customerProfilePage } = useContext(DataContext);
  const [showSnoozed, setShowSnoozed] = useState(false);

  const toggleSnoozedInsights = () => {
    setShowSnoozed(!showSnoozed);
  };

  const hasSnoozedInsights = Array.isArray(customerInsights) && customerInsights.some(item => item.status === 'snoozed');

  const filteredInsights = Array.isArray(customerInsights)
    ? (showSnoozed
      ? customerInsights
      : customerInsights.filter(item => item.status !== 'snoozed'))
    : [];

  const noInsights = filteredInsights.length === 0;

  return (
    <Card className="mt-4 sm:mt-0">
      <CardHeader>
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-lg font-semibold">Insights</h2>
          {hasSnoozedInsights && (
            <div
              onClick={toggleSnoozedInsights}
              className="text-sm text-gray-500 cursor-pointer underline hover:text-blue-500 ml-4 whitespace-nowrap"
            >
              {showSnoozed ? 'Hide Snoozed Insights' : 'View Snoozed Insights'}
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent>
        {noInsights ? (
          <div className="flex flex-wrap gap-2">
            {customerProfilePage?.insight_pills?.map((insight, index) => (
              <Badge key={index} color={getBadgeColor(insight)} variant="outline">
                {insight}
              </Badge>
            ))}
          </div>
        ) : (
          <InsightsList items={filteredInsights} />
        )}
      </CardContent>
    </Card>
  );
}
