import React, { useState, useContext } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { Progress } from 'components/ui/progress';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Pencil, X } from 'lucide-react';
import { DataContext } from 'contexts/DataContext';

export function MinimumSpendPotential() {
  const { customerProfilePage, customerConfig, editCustomerConfig } = useContext(DataContext);
  const [isEditing, setIsEditing] = useState(false);
  const [minSpendValue, setMinSpendValue] = useState(
    customerConfig.min_spend_potential || customerProfilePage.minspendpotential_denominator || 0
  );

  const minspendpotential_numerator = customerProfilePage.minspendpotential_numerator || 0;
  const minspendpotential_denominator = customerConfig.min_spend_potential || customerProfilePage.minspendpotential_denominator;

  let percentage = 0;
  if (minspendpotential_numerator && minspendpotential_denominator) {
    percentage = (minspendpotential_numerator / minspendpotential_denominator) * 100;
    if (percentage > 100) {
      percentage = 100;
    }
  }

  function formatNumber(value) {
    return value ? value.toLocaleString() : '-';
  }

  const handleSave = () => {
    const payload = {
      ...customerConfig,
      min_spend_potential: parseFloat(minSpendValue),
      custcd: customerConfig.custcd
    };
    editCustomerConfig(payload);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setMinSpendValue(customerConfig.min_spend_potential || customerProfilePage.minspendpotential_denominator || 0);
    setIsEditing(false);
  };

  return (
    <div className="mt-4">
      <Card>
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold">Monthly Minimum Spend Potential</h2>
            <div className="flex items-center gap-2">
              {isEditing ? (
                <>
                  <Button onClick={handleCancel} size="sm" variant="outline">
                    Cancel
                  </Button>
                  <Button onClick={handleSave} size="sm">
                    Save
                  </Button>
                </>
              ) : (
                <Pencil onClick={() => setIsEditing(true)} className="w-4 text-gray-500 cursor-pointer" />
              )}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Progress value={percentage} className="mb-2" />
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {!isEditing ? (
                <Text>
                  ${formatNumber(minspendpotential_numerator)} / ${formatNumber(minspendpotential_denominator)}
                </Text>
              ) : (
                <div className="flex items-center gap-2">
                  <Text>${formatNumber(minspendpotential_numerator)} /</Text>
                  <Input
                    type="number"
                    value={minSpendValue}
                    onChange={(e) => setMinSpendValue(e.target.value)}
                    className="w-32"
                  />
                </div>
              )}
            </div>
            <div className="flex items-center gap-4">

              <Text className="text-sm text-gray-500">
                Recommended Target: ${formatNumber(customerProfilePage.minspendpotential_denominator)}
              </Text>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}