import { useState } from 'react'
import { Link } from 'react-router-dom'
import { 
  Send, 
  MessageSquare, 
  MoreHorizontal,
  ArrowLeft,
  Share2,
  Flag,
  Bookmark
} from "lucide-react"
import { 
  Text,
  Title,
  Grid,
  Col,
  Button,
  Badge,
  TextInput,
  Flex,
} from "@tremor/react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  Card,
  CardHeader,
  CardContent,
} from "components/ui/card"
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid"

const post = {
  title: "What's your go-to strategy for upselling premium roofing materials to hesitant customers?",
  author: "User 54321",
  authorAvatar: "/placeholder.svg",
  time: "2h ago",
  content: `I've been in the roofing business for several years, and one consistent challenge I face is convincing customers to invest in premium materials. While I understand their budget concerns, I know that higher-quality materials often provide better long-term value.

  I'm curious about your successful approaches. What strategies have worked for you when explaining the benefits of premium materials? How do you handle price objections while maintaining customer trust?

  Looking forward to hearing your experiences and tips!`,
  topics: ["Sales", "Product"],
  votes: 42,
  comments: [
    {
      id: 1,
      content: "I've found success in showing customers physical samples of premium materials side-by-side with standard options. Letting them see and feel the difference often helps justify the cost. Also, I create a simple cost comparison chart that shows the long-term savings in terms of durability and energy efficiency. Visual aids can be very persuasive!",
      author: "User 67890",
      time: "1d ago",
      votes: 28,
      downvotes: 1
    },
    {
      id: 2,
      content: "One strategy that's worked well for me is to offer a financing option that spreads the cost over time. This can make the premium materials more accessible to budget-conscious customers. I also emphasize the increased home value and potential insurance premium reductions that come with higher-quality roofing.",
      author: "User 13579",
      time: "22h ago",
      votes: 15,
      downvotes: 0
    },
    {
      id: 3,
      content: "I always start by asking customers about their long-term plans for the house. If they're planning to stay for a while, I focus on the comfort and peace of mind that comes with a premium roof. For those thinking of selling, I highlight how a high-quality roof can be a major selling point. Tailoring the pitch to their specific situation has been key for me.",
      author: "User 24680",
      time: "10h ago",
      votes: 7,
      downvotes: 0
    }
  ]
}

// Add color mapping for topics
const topicColors = {
  'Sales': 'emerald',
  'Product': 'blue',
  'Customer Service': 'amber',
  'Technical': 'violet',
  'Market Trends': 'rose',
  'Supply Chain': 'indigo'
}

export default function DetailedPost() {
  const [comment, setComment] = useState('')
  const [postVotes, setPostVotes] = useState(42)
  const [postDownvotes, setPostDownvotes] = useState(3)
  const [isPostUpvoted, setIsPostUpvoted] = useState(false)
  const [isPostDownvoted, setIsPostDownvoted] = useState(false)
  const [voteFlash, setVoteFlash] = useState(null)
  const [commentVoteFlashes, setCommentVoteFlashes] = useState({})

  const handlePostVote = (isUpvote) => {
    if (isUpvote) {
      if (isPostUpvoted) {
        setPostVotes(prev => prev - 1)
        setIsPostUpvoted(false)
      } else {
        setPostVotes(prev => prev + 1)
        if (isPostDownvoted) {
          setPostDownvotes(prev => prev - 1)
          setIsPostDownvoted(false)
        }
        setIsPostUpvoted(true)
      }
      setVoteFlash('up')
    } else {
      if (isPostDownvoted) {
        setPostDownvotes(prev => prev - 1)
        setIsPostDownvoted(false)
      } else {
        setPostDownvotes(prev => prev + 1)
        if (isPostUpvoted) {
          setPostVotes(prev => prev - 1)
          setIsPostUpvoted(false)
        }
        setIsPostDownvoted(true)
      }
      setVoteFlash('down')
    }

    setTimeout(() => {
      setVoteFlash(null)
    }, 1000)
  }

  return (
    <div className="p-2 sm:p-4 md:p-6">
      <Grid numItems={1} numItemsSm={1} numItemsMd={12} className="gap-6">
        {/* Left Sidebar - Full width on mobile, side column on desktop */}
        <Col numColSpan={1} numColSpanMd={3} className="space-y-6">
          {/* Back Button and Stats Card */}
          <Card className="relative">
            <CardContent className="p-4">
              <Link 
                to="/water-cooler" 
                className="inline-flex items-center text-gray-500 hover:text-gray-900 mb-4"
              >
                <ArrowLeft className="h-4 w-4 mr-1" />
                Back to Water Cooler
              </Link>

              <div className="space-y-1.5">
                <Flex alignItems="center" justifyContent="between" className="border-b pb-1">
                  <Text className="text-gray-500 text-sm">Votes</Text>
                  <Text className="font-medium text-gray-700">{postVotes - postDownvotes}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between" className="border-b pb-1">
                  <Text className="text-gray-500 text-sm">Comments</Text>
                  <Text className="font-medium text-gray-700">{post.comments.length}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between">
                  <Text className="text-gray-500 text-sm">Views</Text>
                  <Text className="font-medium text-gray-700">124</Text>
                </Flex>
              </div>
            </CardContent>
          </Card>
        </Col>

        {/* Main Content - Full width on mobile, 9 columns on desktop */}
        <Col numColSpan={1} numColSpanMd={9}>
          <Card className="relative">
            <CardContent className="p-4">
              <Grid numItems={12} className="gap-4 md:gap-6">
                {/* Vote Column */}
                <Col numColSpan={12} numColSpanMd={1}>
                  <Flex 
                    direction={{ initial: "row", md: "col" }}
                    alignItems="center" 
                    justifyContent={{ initial: "start", md: "center" }}
                    className="mb-2 md:mb-0 pt-0.25 md:pr-2"
                  >
                    <Button
                      variant="light"
                      size="xs"
                      icon={ArrowUpIcon}
                      color={isPostUpvoted ? 'green' : 'gray'}
                      className={`transition-transform ${voteFlash === 'up' ? 'scale-125' : ''}`}
                      onClick={() => handlePostVote(true)}
                    />
                    <Text 
                      className="font-bold min-w-[2rem] text-center"
                      color={
                        isPostUpvoted 
                          ? 'green' 
                          : isPostDownvoted
                            ? 'red'
                            : 'gray'
                      }
                    >
                      {postVotes - postDownvotes}
                    </Text>
                    <Button
                      variant="light"
                      size="xs"
                      icon={ArrowDownIcon}
                      color={isPostDownvoted ? 'red' : 'gray'}
                      className={`transition-transform ${voteFlash === 'down' ? 'scale-125' : ''}`}
                      onClick={() => handlePostVote(false)}
                    />
                  </Flex>
                </Col>

                {/* Content Column */}
                <Col numColSpan={12} numColSpanMd={11}>
                  <div className="flex items-center justify-between mb-4">
                    <Flex alignItems="center" className="gap-2 mr-4">
                      <Text color="gray" className="text-sm">
                        {post.author}
                      </Text>
                      <Text color="gray">·</Text>
                      <Text color="gray" className="text-sm">
                        {post.time}
                      </Text>
                    </Flex>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="light" icon={MoreHorizontal} size="sm" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem>
                          <Bookmark className="mr-2 h-4 w-4" />
                          Save Post
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Share2 className="mr-2 h-4 w-4" />
                          Share
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Flag className="mr-2 h-4 w-4" />
                          Report
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>

                  <Title className="text-xl md:text-2xl mb-4">
                    {post.title}
                  </Title>

                  <div className="overflow-x-auto mb-4">
                    <Flex className="gap-2 min-w-max justify-start">
                      {post.topics.map(topic => (
                        <Badge 
                          key={topic} 
                          color={topicColors[topic]}
                          size="sm"
                          className="cursor-pointer hover:opacity-80 transition-colors whitespace-nowrap"
                        >
                          {topic}
                        </Badge>
                      ))}
                    </Flex>
                  </div>

                  <div className="prose max-w-none mb-6">
                    {post.content.split('\n\n').map((paragraph, index) => (
                      <Text key={index} className="mb-4">
                        {paragraph}
                      </Text>
                    ))}
                  </div>

                  {/* Comment Input */}
                  <form className="mt-6" onSubmit={(e) => e.preventDefault()}>
                    <Flex className="gap-4">
                      <TextInput
                        placeholder="Write a comment..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="flex-1"
                      />
                      <Button type="submit" icon={Send} className="shrink-0">
                        Comment
                      </Button>
                    </Flex>
                  </form>
                </Col>
              </Grid>
            </CardContent>
          </Card>

          {/* Comments Section */}
          <Card className="mt-6 relative"> 
            <CardContent className="p-4">
              <Title className="mb-4">Comments ({post.comments.length})</Title>
              <div className="space-y-4">
                {post.comments.map((comment) => (
                  <Card key={comment.id} className="relative">
                    <CardContent className="p-4">
                      <Grid numItems={12} className="gap-4 md:gap-6">
                        {/* Vote Column */}
                        <Col numColSpan={12} numColSpanMd={1}>
                          <Flex 
                            direction={{ initial: "row", md: "col" }}
                            alignItems="center" 
                            justifyContent={{ initial: "start", md: "center" }}
                            className="mb-2 md:mb-0 pt-0.25 md:pr-2"
                          >
                            <Button
                              variant="light"
                              size="xs"
                              icon={ArrowUpIcon}
                              color="gray"
                              className="transition-transform hover:scale-110"
                            />
                            <Text 
                              className="font-bold min-w-[2rem] text-center"
                              color="gray"
                            >
                              {comment.votes - comment.downvotes}
                            </Text>
                            <Button
                              variant="light"
                              size="xs"
                              icon={ArrowDownIcon}
                              color="gray"
                              className="transition-transform hover:scale-110"
                            />
                          </Flex>
                        </Col>

                        {/* Comment Content */}
                        <Col numColSpan={12} numColSpanMd={11}>
                          <Flex alignItems="center" className="gap-2 mb-2">
                            <Text color="gray" className="text-sm font-medium">
                              {comment.author}
                            </Text>
                            <Text color="gray">·</Text>
                            <Text color="gray" className="text-sm">
                              {comment.time}
                            </Text>
                          </Flex>
                          <Text className="text-gray-600">
                            {comment.content}
                          </Text>
                        </Col>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </CardContent>
          </Card>
        </Col>
      </Grid>
    </div>
  )
}
