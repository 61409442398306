// MenuItems.js
import { ArrowUpIcon, ArrowUpTrayIcon, ChatBubbleLeftRightIcon, UserPlusIcon } from '@heroicons/react/16/solid';
import { HomeIcon, SparklesIcon, CheckCircleIcon, Square3Stack3DIcon, PencilSquareIcon, CalendarIcon } from '@heroicons/react/20/solid';

export const menuItems = [
  {
    path: '/',
    label: 'Home',
    page_title: 'Insights Dashboard',
    icon: HomeIcon,
  },
  {
    path: '/customers',
    label: 'Customers',
    page_title: 'Customers',
    icon: Square3Stack3DIcon,
  },
  /*
  {
    path: '/calendar',
    label: 'Calendar',
    page_title: 'Calendar',
    icon: CalendarIcon,
  },
  */
  {
    path: '/lead-generation',
    label: 'Lead Generation',
    page_title: 'Lead Generation',
    icon: UserPlusIcon,
  },
  // {
  //   path: '/reminders',
  //   label: 'Reminders',
  //   page_title: 'Reminders',
  //   icon: CheckCircleIcon,
  // },
  {
    path: '/notes',
    label: 'Notes',
    page_title: 'Notes',
    icon: PencilSquareIcon,
  },
  {
    path: '/email',
    label: 'Email Generator',
    page_title: 'Email Generator',
    icon: SparklesIcon,
  },
  /* {
    path: '/upload',
    label: 'File Upload',
    page_title: 'File Upload',
    icon: ArrowUpTrayIcon,
  },
  {
    path: '/water-cooler',
    label: 'Water Cooler',
    page_title: 'Water Cooler',
    icon: ChatBubbleLeftRightIcon,
  }, */
];
