import React, { useState, useContext, useEffect } from 'react';
import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "components/catalyst/badge";
import DataTableColumnHeader from "./data-table-column-header";
import { formatValue } from 'utils/formatValue';
import { Link } from 'react-router-dom';
import { MoreHorizontal, AlarmClock } from "lucide-react";
import { Button } from "components/ui/button";
import {DropdownMenu,DropdownMenuContent,DropdownMenuItem,DropdownMenuLabel,DropdownMenuSeparator,
  DropdownMenuTrigger,DropdownMenuSub,DropdownMenuSubContent,DropdownMenuSubTrigger} from "components/ui/dropdown-menu";
import { DataContext } from 'contexts/DataContext'
import { AppContext } from 'contexts/AppContext';

const colorMapBackground = {
  'Order Inactivity': 'blue',
  'Net New Customer': 'amber',
  'New Customer Promo': 'cyan',
  'First invoice anniversary': 'purple',
  'First Invoice Anniversary': 'purple',
  'Open AR Payments': 'pink',
  'YoY Sales Drop': 'red',
  'Reduced Invoice Activity': 'orange',
  'Customer DRP dropped': 'rose',
  'Customer DRP Dropped': 'rose',
  'Eligible for new customer promo': 'sky',
  'Eligible For New Customer Promo': 'sky',
  'Customer DRP below expected': 'yellow',
  'Customer DRP Below Expected': 'yellow',
  'First SO Invoiced': 'teal',
  'First Digital SO Invoiced': 'indigo',
  'Limit Increased': 'green',
  'Residential': 'stone'
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || 'gray'; 
};

const ColumnsComponent = () => {
  const { snoozeCustomer, removeSnoozeCustomer, setCustomerReportData } = useContext(DataContext)
  const { logEvent } = useContext(AppContext)

  const toggleSnooze = async (custcd, duration) => {
    const now = new Date();
    let snoozeData;
    console.log(custcd, duration)
    switch (duration) {
      case '1week':
        snoozeData = new Date(now.setDate(now.getDate() + 7));
        break;
      case '1month':
        snoozeData = new Date(now.setMonth(now.getMonth() + 1));
        break;
      default:
        snoozeData = null;
    }

    if (snoozeData) {
      await snoozeCustomer(snoozeData, custcd);
    } else {
      await removeSnoozeCustomer(custcd);
    }

    setCustomerReportData(prevData =>
      prevData.map(customer =>
        customer.custcd === custcd
          ? { ...customer, snooze_date: snoozeData }
          : customer
      )
    );
  };

  const formatSnoozeTime = (snoozeValue) => {
    if (!snoozeValue) return '';

    const snoozeDate = new Date(snoozeValue);
    const now = new Date();
    const diffDays = Math.ceil((snoozeDate - now) / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 7) return 'Snoozed for 1 week';
    if (diffDays <= 30) return 'Snoozed for 1 month';
    
    return `Snoozed until ${snoozeDate.toLocaleDateString()}`;
  };

  const columns = [
    {
      accessorKey: "custname",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer" />
      ),
      cell: ({ row }) => (
        <Link to={`/customer/${row.original.custcd}`}>
          <div className="font-semibold hover:underline">{row.getValue("custname")}</div>
        </Link>
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "insight_pills",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Insights" />
      ),
      cell: ({ row }) => {
        const insights = row.getValue("insight_pills");
        return (
          <div className="flex flex-col space-y-2">
            {insights.map((insight, index) => (
              <Badge key={index} color={getBadgeColor(insight)} variant="outline">{insight}</Badge>
            ))}
          </div>
        );
      },
      filterFn: (row, columnId, filterValue) => {
        const rowValue = row.getValue(columnId);
        return filterValue.length === 0 || rowValue.some(value => filterValue.includes(value));
      }
    },
    {
      accessorKey: "tags",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer Details" />
      ),
      cell: ({ row }) => {
        const details = row.getValue("tags");
        return (
          <div className="flex flex-col space-y-2">
            {details.map((detail, index) => (
              <Badge key={index} variant="outline">{detail}</Badge>
            ))}
          </div>
        );
      },
      filterFn: (row, columnId, filterValue) => {
        const rowValue = row.getValue(columnId);
        return filterValue.length === 0 || rowValue.some(value => filterValue.includes(value));
      }
    },
    {
      accessorKey: "sales_t30d_cy",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="T1M Sales" />
      ),
      cell: ({ row }) => <div>{formatValue(row.getValue("sales_t30d_cy"))}</div>,
    },
    {
      accessorKey: "sales_t365d_cy",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="T12M Sales" />
      ),
      cell: ({ row }) => <div>{formatValue(row.getValue("sales_t365d_cy"))}</div>,
    },
    // {
    //   accessorKey: "snooze_date",
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title="Snooze" />
    //   ),
    //   cell: ({ row }) => {
    //     const snoozeDate = row.getValue("snooze_date");
    //     return snoozeDate ? (
    //       <div>
    //         <Badge variant="outline" className="flex items-center gap-1">
    //           <AlarmClock className="h-3 w-3" />
    //           {formatSnoozeTime(snoozeDate)}
    //         </Badge>
    //         {/* <div className="text-xs text-gray-500 mt-1 ml-2.5">
    //           {formatSetDate(snoozeDate.setAt)}
    //         </div> */}
    //       </div>
    //     ) : (
    //       <div></div>
    //     );
    //   },
    // },
    // {
    //   id: "actions",
    //   cell: ({ row }) => {
    //     const customer = row.original;

    //     return (
    //       <DropdownMenu>
    //         <DropdownMenuTrigger asChild>
    //           <Button variant="ghost" className="h-8 w-8 p-0">
    //             <span className="sr-only">Open menu</span>
    //             <MoreHorizontal className="h-4 w-4" />
    //           </Button>
    //         </DropdownMenuTrigger>
    //         <DropdownMenuContent align="end">
    //           <DropdownMenuSub>
    //             <DropdownMenuSubTrigger>
    //               {customer.snooze_date ? 'Change Snooze' : 'Set to Snooze'}
    //             </DropdownMenuSubTrigger>
    //             <DropdownMenuSubContent>
    //               <DropdownMenuItem onClick={() => toggleSnooze(customer.custcd, '1week')}>
    //                 Snooze for 1 week
    //               </DropdownMenuItem>
    //               <DropdownMenuItem onClick={() => toggleSnooze(customer.custcd, '1month')}>
    //                 Snooze for 1 month
    //               </DropdownMenuItem>
    //             </DropdownMenuSubContent>
    //           </DropdownMenuSub>
    //           {customer.snooze_date && (
    //             <DropdownMenuItem onClick={() => toggleSnooze(customer.custcd, null)}>
    //               Unsnooze
    //             </DropdownMenuItem>
    //           )}
    //           <DropdownMenuSeparator />
    //           <DropdownMenuItem>
    //             <Link to={`/customer/${customer.custcd}`}>View customer details</Link>
    //           </DropdownMenuItem>
    //         </DropdownMenuContent>
    //       </DropdownMenu>
    //     );
    //   },
    // },
  ];

  return columns;
};

export default ColumnsComponent;
